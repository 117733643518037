/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
import { getUserInfo, login, logout, socialLogin, loadDicts } from '@/service/defaultRequest'
import { getToken, removeToken, setToken } from '@/utils/token'
import { Notification, Message } from 'element-ui'
import { resetRouter } from '@/router'
import { getUser, setUser } from "@/utils/storage"
import avatar from "@/assets/images/layout/avatar1.png"

// import i18n from '@/language';

import {
	title, tokenName
} from "@/config"

const state = () => ({
    token: getToken(),
    // username: getUser().nickname || i18n.t("ANONYMITY_NAME"),
    username: getUser().nickname || "anonymity",
    avatar: avatar,
    userInfo: getUser(),
    version: "1.0.0",
    loadReady: 1, // 是否完成初始数据准备0未完成1已完成
})
const getters = {
    token: (state) => state.token,
    username: (state) => state.username,
    avatar: (state) => state.avatar,
    userInfo: (state) => state.userInfo,
    version: (state) => state.version,
    loadReady: (state) => state.loadReady,
}
const mutations = {
    /**
     * @description 设置token
     * @param {*} state
     * @param {*} token
     */
    setToken(state, token) {
        state.token = token
        setToken(token)
    },
    /**
     * @description 设置用户名
     * @param {*} state
     * @param {*} username
     */
    setUsername(state, username) {
		// console.log("setUsername--------------------")
		// console.log(username)
        state.username = username
    },
    /**
     * @description 设置头像
     * @param {*} state
     * @param {*} avatar
     */
    setAvatar(state, avatar) {
        state.avatar = avatar
    },
    /**
     * @description 设置用户信息
     * @param {*} state
     * @param {*} userInfo
     */
    setUserInfo(state, userInfo) {
        setUser(userInfo)
        state.userInfo = userInfo
    },
}

const actions = {
    /**
     * @description 登录拦截放行时，设置虚拟角色
     * @param {*} { commit, dispatch }
     */
    setVirtualRoles({ commit, dispatch }) {
        commit('setAvatar', 'https://i.gtimg.cn/club/item/face/img/2/15922_100.gif')
        commit('setUsername', 'admin(未开启登录拦截)')
    },
    /**
     * @description 登录
     * @param {*} { commit }
     * @param {*} userForm
     */
    async login({ dispatch, commit }, userForm) {
        const baseUrlKey = userForm.baseUrlKey || ""
        const url = userForm.url || ""
        if(userForm.baseUrlKey) {
          delete userForm.baseUrlKey
        }
        if(userForm.url) {
          delete userForm.url
        }
        const { userInfo } = await login(userForm, baseUrlKey, url)
        if (userInfo.id) {
            commit('setToken', userInfo.id)
            commit("setUserInfo", userInfo)
            const hour = new Date().getHours()
            const thisTime =
                hour < 8 ?
                '早上好' :
                hour <= 11 ?
                '上午好' :
                hour <= 13 ?
                '中午好' :
                hour < 18 ?
                '下午好' :
                '晚上好'
			// const title = userInfo.username || userInfo.nickname || i18n.t("ANONYMITY_NAME")
			const title = userInfo.username || userInfo.nickname || "anonymity"
			Notification({
				title: `${thisTime}！`,
				message: `欢迎登录，${title} `
			})
            // console.log("login title--------------------------")
			// console.log(title);
            if (userInfo.username || userInfo.nickname) commit('setUsername', title)
        } else {
            const err = `登录接口异常，请确认后重新登录...`
            Message({
				message: err,
				type: 'error'
			})
            throw err
        }
    },
    /**
     * @description 第三方登录
     * @param {*} {}
     * @param {*} tokenData
     */
    async socialLogin({ dispatch, commit }, tokenData) {
        
    },
    // 为了前端框架正常运行不抛错
    getUserInfo({ commit, dispatch }) {

    },
    /**
     * @description 退出登录
     * @param {*} { dispatch }
     */
    async logout({ dispatch }) {
        // await logout()
        await dispatch('resetAll')
    },
    /**
     * @description 重置token、roles、ability、router、tabsBar等
     * @param {*} { commit, dispatch }
     */
    async resetAll({ commit, dispatch }) {
		// console.log("resetAll----------------------")
        // commit('setUsername', i18n.t("ANONYMITY_NAME"))
        commit('setUsername', "anonymity")
        commit('setUserInfo', {})
        commit('setAvatar', 'https://i.gtimg.cn/club/item/face/img/2/15922_100.gif')
		commit('routes/setRoutes', [], { root: true })
		commit('history/setVisitType', "", { root: true })
		commit('history/setBook', {}, { root: true })
		commit('history/setFamily', {}, { root: true })
		
		localStorage.clear();
		sessionStorage.clear();
        await dispatch('setToken', '')
		// todo待完善
		// await dispatch('acl/setFull', false, { root: true })
		// await dispatch('acl/setRole', [], { root: true })
		// await dispatch('acl/setAbility', [], { root: true })
		// await dispatch('tabs/delAllVisitedRoutes', null, { root: true })
        await resetRouter()
        removeToken()
    },
    /**
     * @description 设置token
     * @param {*} { commit }
     * @param {*} token
     */
    setToken({ commit }, token) {
        commit('setToken', token)
    },
    /**
     * @description 设置头像
     * @param {*} { commit }
     * @param {*} avatar
     */
    setAvatar({ commit }, avatar) {
        commit('setAvatar', avatar)
    },
}
export default { state, getters, mutations, actions }
