import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/basic.scss'
import router from "./router";
import store from "./store";
import i18n from './language';

Vue.config.productionTip = false

// 加载插件
const Plugins = require.context('./plugins', true, /\.js$/)
Plugins.keys().map(Plugins)

Vue.use(ElementUI);

new Vue({
	router,
	store,
	i18n,
	render: h => h(App),
}).$mount('#app')
