<template>
	<!-- 横向布局 -->
	<div class="layout-wrap dlm-layout-wrap">
		<layout-header v-if="!wholePage"></layout-header>
		<div class="layout-content" :class="{
			'whole-page': wholePage,
		}">
			<layout-sidebar v-if="!wholePage"></layout-sidebar>
			<div class="layout-main">
				<layout-tabs></layout-tabs>
				<layout-main v-if="isRouterAlive"></layout-main>
			</div>
		</div>
	</div>
</template>

<script>	
	export default {
		name: 'HzhLayout',
		components: {
			// layout-header
			LayoutHeader: () =>
					import('./LayoutHeader.vue'),
			// layout-sidebar
			LayoutSidebar: () =>
		 		import('./LayoutSidebar.vue'),
			// layout-tabs
			LayoutTabs: () =>
					import('./LayoutTabs.vue'),
			// layout-main
			LayoutMain: () =>
					import('./LayoutMain.vue'),
		},
		data() {
			return {
				isRouterAlive: true,
			}
		},
		provide() {
			return {
				"reload": this.reload
			}
		},
		computed: {
			// 可拖拽配置
			wholePage() {
				return false;
			}
		},
		mounted() {
			// console.log("layout mounted-------------------")
			// console.log(this.isRouterAlive);
			// console.log(this.wholePage);
		},
		methods: {
			reload() {
				this.isRouterAlive = false;
				this.$nextTick(() => {
					this.isRouterAlive = true;
				})
			}
		},
	}
</script>

<style lang="scss" scoped>
	.dlm-layout-wrap {
		height: 100%;

		.layout-header {
			height: 70px;
		}

		.layout-content {
			height: calc(100% - 70px);
			display: flex;

			.layout-sidebar {
				width: $base-left-menu-width;
				background-color: $base-color-white;
				:deep(.el-scrollbar__wrap) {
					overflow-x: hidden;
				}
			}
			
			.layout-main {
			    height: 100%;
			    width: $base-right-content-width;
				display: flex;
				flex-direction: column;
				box-sizing: border-box;
				padding: 12px;
			}

			&.whole-page {
				height: 100%;

				.layout-main {
					width: 100%;
				}
			}
		}
	}
</style>
