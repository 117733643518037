/**
 * @description 格式化时间
 * @param time
 * @param cFormat
 * @returns {string|null}
 */
import html2canvas from 'html2canvas'

import {
	SPECIAL_WORD_STYLE,
	SPECIAL_WORD_RARELY,
	CHAR_SUBSTR_SPECIAL,
	
	BOOK_TYPE_DOCUMENT,
	BOOK_TYPE_CONTENT,
	
	BOOK_PAGE_TYPE_SINGLE,
	BOOK_PAGE_TYPE_DOUBLE,
	
	SUB_LOWERSIZE,
} from "@/config/constent.config"

export function parseTime(time, cFormat) {
	if (arguments.length === 0) {
		return null
	}
	const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
			time = parseInt(time)
			date = new Date(time)
		} else {
			date = ''
		}
		if (typeof time === 'number' && time.toString().length === 10) {
			time = time * 1000
			date = new Date(time)
		} else {
			date = ''
		}
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay(),
	}

	return date ? format.replace(/{([ymdhisa])+}/g, (result, key) => {
		let value = formatObj[key]
		if (key === 'a') {
			return ['日', '一', '二', '三', '四', '五', '六'][value]
		}
		if (result.length > 0 && value < 10) {
			value = '0' + value
		}
		return value || 0
	}) : time
}

/**
 * @description 格式化时间
 * @param time
 * @param option
 * @returns {string}
 */
export function formatTime(time, option) {
	if (('' + time).length === 10) {
		time = parseInt(time) * 1000
	} else {
		time = +time
	}
	const d = new Date(time)
	const now = Date.now()

	const diff = (now - d) / 1000

	if (diff < 30) {
		return '刚刚'
	} else if (diff < 3600) {
		// less 1 hour
		return Math.ceil(diff / 60) + '分钟前'
	} else if (diff < 3600 * 24) {
		return Math.ceil(diff / 3600) + '小时前'
	} else if (diff < 3600 * 24 * 2) {
		return '1天前'
	}
	if (option) {
		return parseTime(time, option)
	} else {
		return (
			d.getMonth() +
			1 +
			'月' +
			d.getDate() +
			'日' +
			d.getHours() +
			'时' +
			d.getMinutes() +
			'分'
		)
	}
}


/**
 * @description 时间戳转化成 年-月-日 时:分：秒
 * @param {*} time
 * @returns {string}
 */
export function TimestampToDate(time) {
	let date = new Date(time)
	let y = date.getFullYear()
	let mon =
		date.getMonth() + 1 < 10 ?
		'0' + (date.getMonth() + 1) :
		date.getMonth() + 1
	let d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
	let h =
		date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
	let min =
		date.getMinutes() < 10 ?
		'0' + date.getMinutes() :
		date.getMinutes()
	let s =
		date.getSeconds() < 10 ?
		'0' + date.getSeconds() :
		date.getSeconds()

	return y + '-' + mon + '-' + d + ' ' + h + ':' + min + ':' + s

}
/**
 * @description 前端数据脱敏化
 * @param {*} str   需要脱敏的数据
 * @param {*} beginLen 开始脱敏的位置
 * @param {*} endLen 结束脱敏的位置
 * @returns
 */
export function Desensitization(str, beginLen, endLen) {
	let len = str.length;
	let firstStr = str.substr(0, beginLen);
	let lastStr = str.substr(endLen);
	let middleStr = str.substring(beginLen, len - Math.abs(endLen)).replace(/[\s\S]/ig, '*');
	let tempStr = firstStr + middleStr + lastStr;
	return tempStr;
}

/**
 * @description 将url请求参数转为json格式
 * @param url
 * @returns {{}|any}
 */
export function paramObj(url) {
	const search = url.split('?')[1]
	if (!search) {
		return {}
	}
	return JSON.parse(
		'{"' +
		decodeURIComponent(search)
		.replace(/"/g, '\\"')
		.replace(/&/g, '","')
		.replace(/=/g, '":"')
		.replace(/\+/g, ' ') +
		'"}'
	)
}

/**
 * @description 从url中获取指定变量名的值
 * @param {Object} paraName 寻找参数的名称
 */
export function getUrlParam(paraName) {
	var tmpurl = document.location.toString();
	var arrObj = tmpurl.split("?");
	var params = {};
	if (arrObj.length > 1) {
		var arrPara = arrObj[1].split("&");
		var arr;
		for (var i = 0; i < arrPara.length; i++) {
			arr = arrPara[i].split("=");
			if (arr != null) {
				params[arr[0]] = arr[1];
			}
		}
	}
	return paraName ? (params[paraName] || "") : params;
}

/**
 * @description 设置url的?后面变量字符串
 * @param {Object} obj
 */
export function setUrlParam(obj) {
	let urlParams = "";
	if (JSON.stringify(obj) != "{}") {
		Object.keys(obj).forEach(key => {
			urlParams += urlParams ? "&" : "";
			urlParams += key + "=" + obj[key];
		})
	}
	return urlParams;
}

/**
 * @description 父子关系的数组转换成树形结构数据
 * @param data
 * @returns {*}
 */
export function translateDataToTree(obj) {
	obj.data = obj.data || []
	obj.idKey = obj.idKey || "id"
	obj.rootId = obj.rootId || ""
	obj.parentKey = obj.parentKey || "parentId"
	obj.childName = obj.childName || "children"
	const {
		data,
		idKey,
		rootId,
		parentKey,
		childName,
	} = obj
	let cloneData = JSON.parse(JSON.stringify(data))
	return cloneData.filter((father) => {
		let childArr = cloneData.filter((child) => {
			return father[idKey] === child[parentKey]
		})
		if (childArr.length) {
			father[childName] = childArr
		}
		return father[parentKey] === rootId || father[parentKey] === undefined || father[parentKey] === null
	})
}

export function translateFamilyTree(obj) {
	obj.data = obj.data || []
	obj.idKey = obj.idKey || "id"
	obj.rootId = obj.rootId || ""
	obj.parentKey = obj.parentKey || "parentId"
	obj.childName = obj.childName || "children"
	obj.spouseKey = obj.spouseKey || "mainId"
	obj.spouseName = obj.spouseName || "spouse"
	const {
		data,
		idKey,
		rootId,
		parentKey,
		childName,
		spouseKey,
		spouseName,
	} = obj
	let cloneData = JSON.parse(JSON.stringify(data))
	return cloneData.filter((father) => {
		if (father[spouseKey] === undefined || father[spouseKey] === null) {
			let childArr = cloneData.filter((child) => {
				return father[idKey] === child[parentKey]
			})
			if (childArr.length) {
				father[childName] = childArr
			}
			let spouseArr = cloneData.filter((spouse) => {
				return father[idKey] === spouse[spouseKey]
			})
			if (spouseArr.length) {
				father[spouseName] = spouseArr
			}
		}
		return father[parentKey] === rootId || ((father[parentKey] === undefined || father[parentKey] ===
			null) && (father[spouseKey] === undefined || father[spouseKey] === null))
	})
}

/**
 * @description 树形结构数据转换成父子关系的数组
 * @param data
 * @returns {[]}
 */
export function translateTreeToData(data) {
	const result = []
	data.forEach((item) => {
		const loop = (data) => {
			result.push({
				id: data.id,
				name: data.name,
				parentId: data.parentId,
			})
			const child = data.children
			if (child) {
				for (let i = 0; i < child.length; i++) {
					loop(child[i])
				}
			}
		}
		loop(item)
	})
	return result
}

/**
 * @description 10位时间戳转换
 * @param time
 * @returns {string}
 */
export function tenBitTimestamp(time) {
	const date = new Date(time * 1000)
	const y = date.getFullYear()
	let m = date.getMonth() + 1
	m = m < 10 ? '' + m : m
	let d = date.getDate()
	d = d < 10 ? '' + d : d
	let h = date.getHours()
	h = h < 10 ? '0' + h : h
	let minute = date.getMinutes()
	let second = date.getSeconds()
	minute = minute < 10 ? '0' + minute : minute
	second = second < 10 ? '0' + second : second
	return y + '年' + m + '月' + d + '日 ' + h + ':' + minute + ':' + second //组合
}

/**
 * @description 13位时间戳转换
 * @param time
 * @returns {string}
 */
export function thirteenBitTimestamp(time) {
	const date = new Date(time / 1)
	const y = date.getFullYear()
	let m = date.getMonth() + 1
	m = m < 10 ? '' + m : m
	let d = date.getDate()
	d = d < 10 ? '' + d : d
	let h = date.getHours()
	h = h < 10 ? '0' + h : h
	let minute = date.getMinutes()
	let second = date.getSeconds()
	minute = minute < 10 ? '0' + minute : minute
	second = second < 10 ? '0' + second : second
	return y + '年' + m + '月' + d + '日 ' + h + ':' + minute + ':' + second //组合
}

/**
 * @description 获取随机id
 * @param length
 * @returns {string}
 */
export function uuid(length = 32) {
	const num = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
	let str = ''
	for (let i = 0; i < length; i++) {
		str += num.charAt(Math.floor(Math.random() * num.length))
	}
	return str
}

/**
 * @description m到n的随机数
 * @param m
 * @param n
 * @returns {number}
 */
export function random(m, n) {
	return Math.floor(Math.random() * (m - n) + n)
}

/**
 * @description addEventListener
 * @type {function(...[*]=)}
 */
export const on = (function() {
	return function(element, event, handler, useCapture = false) {
		if (element && event && handler) {
			element.addEventListener(event, handler, useCapture)
		}
	}
})()

/**
 * @description removeEventListener
 * @type {function(...[*]=)}
 */
export const off = (function() {
	return function(element, event, handler, useCapture = false) {
		if (element && event) {
			element.removeEventListener(event, handler, useCapture)
		}
	}
})()

/**
 * @description 数组打乱
 * @param array
 * @returns {*}
 */
export function shuffle(array) {
	let m = array.length,
		t,
		i
	while (m) {
		i = Math.floor(Math.random() * m--)
		t = array[m]
		array[m] = array[i]
		array[i] = t
	}
	return array
}

/**
 * @description 深度复制
 * @param source 任意类型
 * @return
 */
export function deepCopy(source) {
	if (typeof source != "object") {
		return source
	}
	if (source == null) {
		return source
	}
	let newObj = source.constructor === Array ? [] : {} //开辟一块新的内存空间
	for (let i in source) {
		newObj[i] = deepCopy(source[i])
	}
	return newObj
}

// 将obj2的属性赋值给obj1
export function deepExtend(obj1, obj2) {
	obj2 = obj2 || {}

	function copyFn(obj1, obj2) {
		let result
		if (obj2 == null || obj2 == undefined) {
			if (Array.isArray(obj1)) {
				// 数组直接赋值，而不是原数组concat
				result = obj1.concat()
			} else if (typeof obj1 == "function") {
				// 函数直接赋值
				result = obj1
			} else {
				result = {
					...obj1
				};
			}
			return result
		}
		if (Array.isArray(obj2)) {
			// 数组直接赋值，而不是原数组concat
			result = obj2.concat()
		} else if (typeof obj2 == "function") {
			// 函数直接赋值
			result = obj2
		} else {
			if (!obj1) {
				result = {
					...obj2
				}
			} else {
				result = {
					...obj1,
					...obj2
				}
				for (let key in obj2) {
					if (obj2.hasOwnProperty(key)) {
						if (typeof obj2[key] === 'object' && obj2[key] !== null) {
							// 如果是对象，再次调用该方法自身
							result[key] = copyFn(obj1[key], obj2[key])
						}
					}
				}
			}
		}
		return result;
	}
	return copyFn(obj1, obj2);
}

/**
 * 精确计算
 * @param num1被加数/被减数/被乘数/被除数(num2不为0时)
 * @param num2加数/减数/乘数/除数
 * @param type算法类型
 * @return
 */
export function fillMath(num1, num2, type) {
	type = type || "plus";
	let tmpNum1 = String(num1);
	let tmpNum2 = String(num2);
	tmpNum1 = tmpNum1.split(".");
	tmpNum2 = tmpNum2.split(".");
	let len1 = tmpNum1.length == 2 ? tmpNum1[1].length : 0;
	let len2 = tmpNum2.length == 2 ? tmpNum2[1].length : 0;
	let maxLen = len1 > len2 ? len1 : len2;
	let powNum = 1;
	if (maxLen) {
		powNum = Math.pow(10, maxLen);
	}
	let result = 0;
	switch (type) {
		// 除法
		case "division":
			result = num2 ? (num1 * powNum) / (num2 * powNum) : 0;
			break;
			// 乘法
		case "multiply":
			result = (num1 * powNum * num2 * powNum) / (powNum * powNum);
			break;
			// 减法
		case "minus":
			result = (num1 * powNum - num2 * powNum) / powNum;
			break;
			// 加法
		default:
			result = (num1 * powNum + num2 * powNum) / powNum;
			break;
	}
	return result;
}

/**
 * 转译JAVA数据类型为前端组件——仅用于前端没有在fields.js里面维护字段信息
 * @param {Object} arg
 * moduleName 模块名
 * prop 数据表字段名
 * type JAVA数据类型
 * length 数据表定义字段长度
 */
export function transFieldType(arg) {
	arg.forceType = arg.forceType || "SinoText"
	const special = {
		// 用户
		"user": [
			"password", // 密码
			"login_name", // 登录名
			"operatorname", // 操作员姓名
		],
		// 行政区划
		"area": [
			"area_name", // 区域名称
			"area_name_en", // 区域英文名
			"pinyin_name", // 区域全拼
			"short_name", // 区域简称
		]
	}
	let str = ""
	switch (arg.type) {
		// 字符串
		case "character":
			str = special[arg.moduleName] && special[arg.moduleName].includes(arg.prop) ? arg.forceType : (arg.length >
				50 ? "SinoTextarea" : "SinoText")
			break;
		default:
			// type：long,integer
			str = "SinoText"
			break;
	}
	return str
}

/**
 * @param {Object} obj
 */
export function objIsEmpty(obj, specials) {
	let count = Object.keys(obj).length;
	specials = specials || ['0']

	for (let key in obj) {
		if (!specials.includes(obj[key]) && !obj[key]) {
			count--
		}
	}

	return !count;
}

/**
 * @desc 将某一个人信息按照规则处理成齿录内容列
 * @param {Object} str 齿录详情
 * @param {Object} rowSize 每条齿录包含的行数——即单一内容列的字数
 * @param {Object} replaceStr 替换换行符所用到的字符串
 * @param {Object} lowerSize 附属信息比主体信息降格，默认为1——所有的个人信息都放在i=0的字符串，剩下均为附属信息，比i=0的个人信息要降格（也就是不再用空格代替降格处理）
 * 尤其注意：
 * 1、首空格字符要去除！！！
 * 2、信息格式：主体、赞/传/祝、子女
 */
export function makeRecordContents(obj) {
	obj.str = obj.str || ""
	obj.rowSize = obj.rowSize || 30
	obj.replaceStr = obj.replaceStr || "#"
	obj.lowerSize = obj.lowerSize || SUB_LOWERSIZE;
	let {
		str, rowSize, replaceStr, lowerSize
	} = obj;

	str = replaceLine(str, replaceStr) // 所有的换行符

	let contents = []
	if (str) {
		let arr = str.split(replaceStr)
		let subContents = []
		for (let i = 0; i < arr.length; i++) {
			subContents = strToArr({
				str: arr[i],
				size: i == 0 ? rowSize : (rowSize - obj.lowerSize),
				ignoreFirstSpace: true,
				singleLineArr: CHAR_SUBSTR_SPECIAL
			})
			subContents.forEach(sub => {
				contents.push({
					type: i == 0 ? 'main' : 'sub', // 暂时所有附属信息不做区分，后续有需要再优化
					str: sub
				});
			});
		}
	}
	return contents
}

/**
 * @desc 将字符串中的换行符统一替换成#
 * @param {Object} str
 */
function replaceLine(str, replaceStr) {
	str = str || "";
	replaceStr = replaceStr || '#'
	str = JSON.stringify(str)
	str = str.replace(/\\r\\n/g, replaceStr)
	str = str.replace(/\\n/g, replaceStr)
	return JSON.parse(str)
}

/**
 * @desc 将字符串中的一个或多个空格替换成指定字符串
 * @param {Object} str
 */
export function replaceEmpty(str, replace) {
	str = str || "";
	str = replaceLine(str);
	replace = replace || " "
	let reg = /\s+/g
	return str.replace(reg, replace)
}

/**
 * @desc 字符串根据自定义长度分组
 * @param String str 待分组字符串
 * @param Number size 分组大小
 * @param Boolean once 是否只一次
 * @param ignoreFirstSpace 自然分割（非换行）后的首字符为空格是否忽略
 */
export function strToArr(obj) {
	obj.str = obj.str || ""
	obj.size = obj.size || 10
	obj.once = obj.once || false
	obj.ignoreFirstSpace = obj.ignoreFirstSpace || false;
	obj.singleLineArr = obj.singleLineArr || [];
	const {
		str,
		size,
		once,
		singleLineArr,
		ignoreFirstSpace,
	} = obj

	let arr = [""]
	if (str) {
		const originalStr = str
		if (!once) {
			let strLen = 0,
				i = 0;
			let realOriginStr = ignoreFirstSpace ? handleStrFirstSpace({
					str: originalStr, size, singleLineArr,
			}) : originalStr; // originalStr在指定分割处替换了首字母的字符串
			while (strLen < realOriginStr.length) {
				arr[i] = realOriginStr.substr(strLen, singleLineArr.includes(realOriginStr[strLen + size]) ? (size + 1) : size)
				strLen += arr[i].length;
				i++;
			}
		} else {
			arr[0] = originalStr.substr(0, singleLineArr.includes(originalStr[size]) ? (size + 1) : size)
			arr[1] = ignoreFirstSpace && originalStr[arr[0].length] == ' ' ? originalStr.substr(arr[0].length + 1) : originalStr.substr(arr[0].length)
		}
	}
	return arr
}

/**
 * 按照分割字符大小，替换分割处下一个子串的首个空格字符
 * @param {Object} obj.str待处理的字符串
 * @param {Object} obj.size分割的尺寸
 * @param {Object} obj.singleLineArr 是否需要考虑不能放置到行首的字符集，true需要参照CHAR_SUBSTR_SPECIAL
 */
function handleStrFirstSpace(obj) {
	obj.str = obj.str || "";
	obj.size = obj.size || 10;
	obj.singleLineArr = obj.singleLineArr || [];
	const {
		str, size, singleLineArr
	} = obj;
	let realStr = "", count = 0, startSize = 0, endSize = 0;
	while(count < str.length) {
		startSize = count;
		if(str[count] == ' ') {
			startSize++;
		}
		endSize = size;
		if(singleLineArr.includes(str[startSize + size])) {
			endSize = size + 1;
		}
		realStr += str.substr(startSize, endSize);
		count += endSize;
	}
	return realStr;
}

// 统计某个字符在字符串中出现的位置
export function statisticIndexOf(str, targetChar) {
	let indexs = []
	let index = str.indexOf(targetChar)
	while (index !== -1) {
		indexs.push(index)
		index = str.indexOf(targetChar, index + 1) // 从字符串出现的位置的下一位置开始继续查找
	}
	return indexs
}

// 替换后端用字段生僻字
export function handleSpecialWordsBack(str) {
	str = str || "";

	let specialWords = [],
		content = str;

	// console.log("原始字符串--------------------" + str);
	if (str) {
		let rarelyWords = str.match(/\[(.+?)\]/g) || [];
		if (rarelyWords && rarelyWords.length) {
			rarelyWords.forEach(rarely => {
				content = content.replace(rarely, "x");
			})
		}
		specialWords = rarelyWords.concat();
	}
	// console.log("最终字符串--------------------" + content);
	// console.log(specialWords)
	return {
		specialWords,
		content
	}
}
export function handleSpecialWordsNew(str, replaceStr) {
	str = str || "";
	replaceStr = replaceStr || "#";

	let specialWords = [],
		content = str;

	// console.log("原始字符串--------------------" + str);
	if (str) {
		var regex = new RegExp(replaceStr, 'gi');
		let tmpStr = replaceLine(str).replace(regex, '');
		let regex1 =  /\[(.+?)\]/g
		let item = null
		let arr1 = []
		content = str;
		while((item = regex1.exec(tmpStr)) !== null){
			if(item[0].indexOf("{") > -1) {
				content = content.replace(item[0], SPECIAL_WORD_STYLE);
			} else {
				content = content.replace(item[0], typeof item[1] == "string" && parseInt(item[1]) > 0 ? SPECIAL_WORD_RARELY : "x");
			}
			specialWords.push(item)
		}
		specialWords.forEach((rarely, rindx) => {
			/**
			 * index：字符串中生僻字/样式字的起始序号
			 * contentIndex：字符串中替换了生僻字/样式字的之后s/r/x的序号
			 */
			if(rindx) {
				/**
				 * 非第一个生僻字/样式字，需要减去上一个生僻字/样式字（(字符串长度-1)+(原始字符串起始位置-它被替换后的s/r/x的起始序号)），1是上一个替换进来的s/r/x的字符长度
				 * 	举例：
				 * 		原始字符串：生僻字样式字不[12]生僻字样式[{width:12px,height:12px}]生僻字生僻字样式字不[24]生僻
				 * 		替换字符串：生僻字样式字不r生僻字样式s生僻字生僻字样式字不r生僻
				 * 			第一个生僻字的index是7，长度是4，替换后的contentIndex跟index一样是7；
				 * 			第二个样式字的index是16，长度是24，替换后的contentIndex是13；
				 * 			第三个生僻字的index是52，长度是4，替换后的contentIndex是26。
				 */
				rarely['contentIndex'] = rarely['index'] - ((specialWords[rindx - 1][0].length - 1) + (specialWords[rindx - 1]['index'] - specialWords[rindx - 1]['contentIndex']));
			} else {
				rarely['contentIndex'] = rarely['index']
			}
		})
	}
	return {
		specialWords,
		content
	}
}

// 字体最终大小转化
export function fontSize(size) {
	if (!isNaN(parseFloat(size)) || (typeof size == "string" && size.indexOf("%") > -1)) {
		let el = document.getElementById("app") || {
			offsetWidth: 1920,
			offsetHeight: 1080
		};

		if (el.offsetWidth < 1440) el.offsetWidth = 1440
		if (el.offsetHeight < 900) el.offsetWidth = 900


		let realSize = typeof size == "string" && size.indexOf("%") > -1 ? size.substr(0, size.length - 1) : size;
		if (el.offsetWidth / 1920 < el.offsetHeight / 1080) {
			// 以宽度定——过高
			realSize = realSize * el.offsetWidth / 1920;
		} else {
			// 以高度定——过宽
			realSize = realSize * el.offsetHeight / 1080;
		}
		return typeof size == "string" && size.indexOf("%") > -1 ? Math.floor(realSize) + "%" : Math.floor(realSize);
	}
	return size;
}

export function xpconsole(name, str) {
	str = str || ""
	console.log(name + "------------------------")
	console.log(str)
}

// 替换前端用字段特殊字——包括生僻字和样式字
export function handleSpecialWords(str) {
	str = str || "";

	let specialWords = [],
		content = str;

	// xpconsole("原始字符串", str);
	if (str) {
		let styleWords = str.match(/\[style:(.*?)\]/g) || [];
		// xpconsole("styleWords", styleWords);
		if (styleWords && styleWords.length) {
			styleWords.forEach(style => {
				content = content.replace(style, SPECIAL_WORD_STYLE);
			})
		}
		let rarelyWords = str.match(/\[image:(.*?)\]/g) || [];
		// xpconsole("rarelyWords", rarelyWords);
		if (rarelyWords && rarelyWords.length) {
			rarelyWords.forEach(rarely => {
				content = content.replace(rarely, SPECIAL_WORD_RARELY);
			})
		}
		specialWords = specialWords.concat(styleWords, rarelyWords);
	}
	// xpconsole("最终字符串", content);
	// xpconsole("最终特殊字符集", specialWords);
	if(content.includes("[") && !specialWords.length) {
		return handleSpecialWordsBack(str)
	} else {
		return {
			specialWords,
			content
		}
	}
}
/**
 * 给字符串补充空格
 * @param {Object} str 待补空格字符串
 * @param {Object} size 分组大小
 */
function supplyEmpty(str, size) {
	str = str || ""
	const mod = str.length % size
	if (mod) {
		for (let i = mod; i < size; i++) {
			str += " "
		}
	}
	return str
}
// 对按规则分行后的齿录集再按规则进行分页
export function spliceContent(obj) {
	obj.pageSize = obj.pageSize || 20; // 分页行数
	obj.pageType = obj.pageType || BOOK_PAGE_TYPE_SINGLE; // 卷名占据行高/宽
	obj.volumeSize = obj.volumeSize || 2; // 卷名占据行高/宽
	obj.partSize = obj.partSize || 2; // 分支名占据行高/宽
	obj.generationSize = obj.generationSize || 4; // 世代占据行高/宽
	obj.contentSize = obj.contentSize || 2; // 内容首行占据行高/宽
	obj.spouseAttached = obj.spouseAttached || false; // 配偶是否附属主节点
	const {
		arr,
		pageSize,
		pageType,
		volumeSize,
		partSize,
		generationSize,
		contentSize,
		spouseAttached,
	} = obj;
	let patchs = [], count = 0, tmp = 1, tmpPatch = [];
	if(pageType == BOOK_PAGE_TYPE_DOUBLE) {
		tmpPatch = [[],[]];
	}
	arr.forEach(record => {
		switch(record.type) {
			// 卷名
			case 4:
				tmp = volumeSize;
				break;
			// 分支名
			case 3:
				tmp = partSize;
				break;
			// 派名
			case 2:
				tmp = generationSize;
				break;
			// 齿录
			default:
				if((record.relationship && record.relationship.length) || (record.name && record.name.length)) {
					// 首行
					tmp = record.relativeFlag == 2 && spouseAttached ? 1 : contentSize;
				} else {
					// 非首行
					tmp = 1;
				}
				break;
		}
		if(pageType == BOOK_PAGE_TYPE_SINGLE) {
			if(count + tmp > pageSize) {
				patchs.push(tmpPatch);
				// 超出1页内容，强行换页
				tmpPatch = [];
				count = 0;
			}
			tmpPatch.push(record);
			count += tmp;
		} else {
			if(count + tmp > pageSize * 2) {
				patchs.push(tmpPatch);
				// 超出1页内容，强行换页
				tmpPatch = [[],[]];
				count = 0;
			}
			if(count + tmp > pageSize) {
				tmpPatch[1].push(record);
			} else {
				tmpPatch[0].push(record);
			}
			count += tmp;
		}
	})
	/* if(tmpPatch.length) {
		patchs.push(tmpPatch);
		// 超出1页内容，强行换页
		tmpPatch = [];
		count = 0;
	} */
	return {
		patchs, tmpPatch
	};
}
/**
 * @desc 将数组按照自定义大小切割成若干个数组
 * @param {Object} arr 待分配数组
 * @param {Object} size 分组大小
 */
export function splitArrBySize(arr, size) {
	arr = arr || []
	size = size || 6
	let newArr = []
	if (arr.length) {
		let length = Math.ceil(fillMath(arr.length, size, "division"))
		for (let i = 0; i < length; i++) {
			newArr[i] = arr.splice(0, size)
		}
	}
	return newArr
}

/**
 * 组装齿录
 * @param Array ids
 * @param Array types
 * @param Array relationships
 * @param Array names
 * @param Array contents
 * @param Number patchSize 一页的齿录数量
 * @param Array tmpColumns 上一次未渲染的齿录集
 * @param Array wholeGenerations 齿录世代
 */
export function makePatches(obj) {
	obj.ids = obj.ids || []
	obj.types = obj.types || []
	obj.relationships = obj.relationships || []
	obj.names = obj.names || []
	obj.contents = obj.contents || []
	obj.patchSize = obj.patchSize || 6
	obj.tmpColumns = obj.tmpColumns || []
	obj.generations = obj.generations || []
	const {
		ids,
		types,
		relationships,
		names,
		contents,
		patchSize,
		tmpColumns,
		generations
	} = obj

	let columns = tmpColumns.concat()
	for (let i = 0; i < relationships.length; i++) {
		columns.push({
			id: ids[i],
			generation: generations[i],
			type: types[i],
			relationship: relationships[i] || "",
			name: names[i] || "",
			content: contents[i] || "",
		})
	}
	const patches = splitArrBySize(columns, patchSize)
	return patches
}

// html页码转图片:posterBoxRef:unref(posterBox)
export function html2Image(ele, successCall, failCall) {
	const width = ele.width || ele.offsetWidth; //获取dom 宽度
	const height = ele.height || ele.offsetHeight; //获取dom 高度
	/* let canvas = document.createElement("canvas"); //创建一个canvas节点

	const scale = 2; //定义任意放大倍数 支持小数
	canvas.width = width * scale; //定义canvas 宽度 * 缩放
	canvas.height = height * scale; //定义canvas高度 *缩放
	canvas.getContext("2d").scale(scale, scale); //获取context,设置scale */
	
	setTimeout(() => {
		html2canvas(ele, {
			// 加上宽高以后，白边消失，如右图
			width: width,
			height: height,

			// scale: scale, // 添加的scale 参数
			// canvas: canvas, //自定义 canvas
			logging: true, //日志开关，便于查看html2canvas的内部执行流程
			letterRendering: 1,
			useCORS: true, // 【重要】开启跨域配置
			allowTaint: false,

		}).then(canvasObj => {
			const context = canvasObj.getContext('2d');
			// 【重要】关闭抗锯齿
			context.mozImageSmoothingEnabled = false;
			context.webkitImageSmoothingEnabled = false;
			context.msImageSmoothingEnabled = false;
			context.imageSmoothingEnabled = false;

			// 【重要】默认转化的格式为png,也可设置为其他格式
			const dataURL = canvasObj.toDataURL('image/png');
			// todo
			successCall && successCall(dataURL)
		}).catch(e => {
			failCall && failCall();
		});
	}, 100);
}
/**
 * 数字转汉字
 * @param  Number num   数字
 * @param  Boolean issup 是否大写，默认是小写
 * @param  Boolean replace 是否需要转译：二十变成廿，三十变成卅，默认不需要
 * @param  Boolean cutFirst 当第一个数字为1时，是否需要剪掉，默认不需要
 * @param  Boolean ignore 是否忽略进制，默认不需要
 * @return String       数字对应的汉字
 */
export function parseNum(obj) {
	obj.num = obj.num
	obj.issup = obj.issup
	obj.replace = obj.replace
	obj.cutFirst = obj.cutFirst
	obj.ignore = obj.ignore

	const {
		num,
		issup,
		replace,
		cutFirst,
		ignore
	} = obj;

	var isNeg = num < 0; // 是否负数
	var realNum = num.toString();

	if (!isNeg && realNum.includes("-")) {
		isNeg = true;
	}
	if (isNeg) {
		alert("负数暂不支持");
		return;
	}

	var isDecimal = realNum.includes(".");
	if (isDecimal) {
		alert("小数暂不支持");
		return;
	}
	let numArr = realNum.split("."); // numArr[0]是整数 numArr[1]是小数
	var len = numArr[0].length;
	if (len > 5) {
		alert("超大数字，暂不支持");
		return;
	}
	var digit, unit;
	if (issup) {
		digit = ["零", "壹", "貳", "叁", "肆", "伍", "陸", "柒", "捌", "玖"];
		unit = ["", "拾", "佰", "仟", "萬", "亿"];
	} else {
		digit = ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
		unit = ["", "十", "百", "千", "万", "亿"];
	}

	var result = '';
	for (var i = 0; i < len; i++) {
		var index = len - i - 1;
		var current = digit[parseInt(numArr[0][index])];
		var next = digit[parseInt(numArr[0][index - 1])] || '';
		if (current === digit[0] && next === digit[0]) continue;
		if (current === digit[0]) current = '';
		if (result.length && len - 1 === i && current === '一' && cutFirst) current = '';

		result = current ? (current + unit[i] + result) : (len - 1 === i ? (unit[i] + result) : (digit[0] + result));

	}
	// 去掉末尾的digit[0]
	while (result.length && len > 1 && result.lastIndexOf(digit[0]) == result.length - 1) {
		result = result.slice(0, -1);
	}
	return result;
}
/**
 * 数字转汉字
 * @param  Number num   数字
 * @param  Boolean issup 是否大写，默认是小写
 * @param  Boolean replace 是否需要转译：二十变成廿，三十变成卅，默认不需要
 * @param  Boolean cutFirst 当第一个数字为1时，是否需要剪掉，默认不需要
 * @param  Boolean ignore 是否忽略进制，默认是不忽略
 * @return String       数字对应的汉字
 */
export function transNum(num, issup, replace, cutFirst, ignore) {
	issup = issup || false;
	replace = replace || false;
	cutFirst = cutFirst || false;
	ignore = ignore || false;

	function divisionTen(tmpNum, ignore, issup, countDivision) {
		countDivision = countDivision || 0;
		var str = "",
			tmpStr = "",
			unitStr = "";
		var tenStr, unitStr1;
		if (issup) {
			tenStr = ["零", "壹", "貳", "叁", "肆", "伍", "陸", "柒", "捌", "玖"];
			unitStr1 = ["拾", "佰", "仟", "萬", "亿", "兆"];
		} else {
			tenStr = ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
			unitStr1 = ["十", "百", "千", "万", "亿", "兆"];
		}
		var intNum = parseInt(tmpNum / 10);
		var slotNum = tmpNum % 10;
		countDivision++;
		tmpStr = tenStr[slotNum];
		if (intNum >= 10) {
			tmpStr = ignore ? tmpStr : unitStr1[countDivision - 1] + tmpStr;
			str = divisionTen(intNum, ignore, issup, countDivision) + tmpStr;
		} else {
			if (intNum) {
				if(intNum == 1 && countDivision == 1) {
					unitStr = unitStr1[countDivision - 1];
				} else if (countDivision == 1 && intNum > 1 && !slotNum) {
					unitStr = tenStr[intNum] + unitStr1[countDivision - 1];
				} else {
					unitStr = ignore ? tenStr[intNum] : tenStr[intNum] + unitStr1[countDivision - 1];
				}
			}
			str = unitStr + tmpStr;
		}
		return str;
	}



	var numChars = divisionTen(num, ignore, issup);
	// 去掉第一个字符为一/壹的
	var firstChar = numChars.substr(0, 1);
	if (ignore) cutFirst = false;
	if (cutFirst && (firstChar == "一" || firstChar == "壹") && numChars != "一" && numChars != "壹") {
		numChars = numChars.substr(1);
	}
	var replaceNum = num % 100;
	if (replaceNum > 20 && replaceNum < 30 && replace) {
		numChars = numChars.replace("二十", "廿");
		numChars = numChars.replace("貳拾", "廿");
	}
	if (replaceNum > 30 && replaceNum < 40 && replace) {
		numChars = numChars.replace("三十", "卅");
		numChars = numChars.replace("叁拾", "卅");
	}
	numChars = numChars.replace("十〇", "十");
	numChars = numChars.replace("拾零", "拾");
	return numChars;
}

/**
 * 数字转汉字
 * @param  Number num   数字
 * @param  Boolean issup 是否大写，默认是小写
 * @param  Boolean replace 是否需要转译：二十变成廿，三十变成卅，默认不需要
 * @param  Boolean cutFirst 当第一个数字为1时，是否需要剪掉，默认不需要
 * @param  Boolean ignore 是否忽略进制，默认是不忽略
 * @return String       数字对应的汉字
 */
function changeNumToHan(obj) {
	obj.num = obj.num || 1;
	obj.issup = obj.issup || false;
	obj.replace = obj.replace || false;
	obj.cutFirst = obj.cutFirst != undefined ? obj.cutFirst : true;
	obj.ignore = obj.ignore || false;
	const {
		num,
		issup,
		replace,
		cutFirst,
		ignore,
	} = obj;


	var arr1 = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
	var arr2 = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿']
	if (!num || isNaN(num)) return '零'
	var english = num.toString().split('')
	var result = ''
	for (var i = 0; i < english.length; i++) {
		var des_i = english.length - 1 - i // 倒序排列设值
		result = arr2[i] + result
		var arr1_index = english[des_i]
		result = arr1[arr1_index] + result
	}
	result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十') // 将【零千、零百】换成【零】 【十零】换成【十】
	result = result.replace(/零+/g, '零') // 合并中间多个零为一个零
	result = result.replace(/零亿/g, '亿').replace(/零万/g, '万') // 将【零亿】换成【亿】【零万】换成【万】
	result = result.replace(/亿万/g, '亿') // 将【亿万】换成【亿】
	result = result.replace(/零+$/, '') // 移除末尾的零
	result = result.replace(/^一十/g, '十') // 将【一十】换成【十】
	// result = result.replace(/^一百/g, '百')// 将【一百】换成【百】
	return result
}

// 公历纪年转干支纪年
// 扩展：格里历公元1582年10月15日正式生效（即儒略历公元1582年10月5日），即公历1582年10月15日之前的日期全部不是公历纪年
export function getOldDate(year) {
	var baseYear = 1; // 公元元年
	var trunceDeltar = 7; // 公元元年是辛酉年，辛为第8（角标为7）
	var branchDeltar = 9; // 公元元年是辛酉年，申为第10（角标为9）
	// 天干
	var truncus = [
		"甲",
		"乙",
		"丙",
		"丁",
		"戊",
		"己",
		"庚",
		"辛",
		"壬",
		"癸"
	];
	// 地支
	var branches = [
		"子",
		"丑",
		"寅",
		"卯",
		"辰",
		"巳",
		"午",
		"未",
		"申",
		"酉",
		"戌",
		"亥"
	];
	var deltarYears = parseInt(year) - baseYear;
	var truncuIndx = (deltarYears + trunceDeltar) % 10;
	var branchIndx = (deltarYears + branchDeltar) % 12;
	var yearName = truncus[truncuIndx] + branches[branchIndx];

	return yearName;
}

/**
 * 将数字公元年转为汉字+干支
 * @param {Object} year年份
 */
export function transYearWithOld(year) {
	year = year || new Date().getFullYear();
	return "公元" + transNum(year, false, false, false, true) + "年" + getOldDate(year) + "岁";
}

/**
 * 将数字世代转为汉字+后缀
 * @param {Object} generation世代
 */
export function transGeneration(obj) {
	obj.prefix = obj.prefix == undefined ? "第" : obj.prefix;
	obj.generation = obj.generation || 1;
	obj.suffix = obj.suffix || "世";
	obj.issup = obj.issup || false;
	obj.replace = obj.replace || false;
	obj.cutFirst = obj.cutFirst != undefined ? obj.cutFirst : true;
	obj.ignore = obj.ignore || false;
	const {
		prefix,
		generation,
		suffix,

		issup,
		replace,
		cutFirst,
		ignore,
	} = obj;


	/**
	 * @param  Number num   数字
	 * @param  Boolean issup 是否大写，默认是小写
	 * @param  Boolean replace 是否需要转译：二十变成廿，三十变成卅，默认不需要
	 * @param  Boolean cutFirst 当第一个数字为1时，是否需要剪掉，默认不需要
	 * @param  Boolean ignore 是否忽略进制，默认是不忽略
	 */
	return prefix + changeNumToHan({
		num: generation,
		issup,
		replace,
		cutFirst,
		ignore,
	}) + suffix;
}

// 首字母大小
export function titleCase(str) {
	str = str || ""
	return str.replace(/( |^)[a-z]/g, L => L.toUpperCase())
}

//保留两位小数
export function toDecimal(value, length) {
	value = value || 0;
	length = length || 2;
	value = Math.round(parseFloat(value) * 100) / 100;
	var xsd = value.toString().split(".");
	if (length == undefined) {
		length = 2;
	}
	if (length == 0) {
		return xsd[0];
	}
	let decimal = "";
	if (xsd.length == 1) {
		for (let i = 0; i < length; i++) {
			decimal += "0";
		}
		value = value.toString() + "." + decimal;
	} else {
		if (xsd[1].length < length) {
			for (let i = xsd[1].length; i < length; i++) {
				decimal += "0";
			}
			value = value.toString() + decimal;
		} else {
			value = xsd[0] + "." + xsd[1].substr(0, length);
		}
	}
	return value;
};

// 滚动到目标区域
export function scrollIntoView(container, selected, isHoriable) {
	var offsetParents = [];
	var pointer = selected ? selected.offsetParent : null;
	while (pointer && container !== pointer && container.contains(pointer)) {
		offsetParents.push(pointer);
		pointer = pointer.offsetParent;
	}

	if (isHoriable) {
		if (!selected) {
			container.scrollLeft = 0;
			return;
		}

		var left = selected.offsetLeft + offsetParents.reduce(function(prev, curr) {
			return prev + curr.offsetLeft;
		}, 0);
		var right = left + selected.offsetWidth;
		var viewRectLeft = container.offsetLeft;
		var viewRectRight = viewRectLeft + container.clientWidth;

		if (left < viewRectLeft + container.scrollLeft) {
			// 目标左侧在视窗左侧外
			container.scrollLeft = Math.min(container.scrollLeft, left);
		} else if (right > viewRectRight) {
			// 目标右侧在视窗右侧外
			container.scrollLeft = Math.max(container.scrollLeft, (right - container.clientWidth));
		} else {
			// todo
		}
	} else {
		if (!selected) {
			container.scrollTop = 0;
			return;
		}

		var top = selected.offsetTop + offsetParents.reduce(function(prev, curr) {
			return prev + curr.offsetTop;
		}, 0);
		var bottom = top + selected.offsetHeight;
		var viewRectTop = container.scrollTop;
		var viewRectBottom = viewRectTop + container.clientHeight;

		if (top < viewRectTop + container.scrollTop) {
			container.scrollTop = Math.min(container.scrollTop, top);
		} else if (bottom > viewRectBottom) {
			container.scrollTop = Math.max(container.scrollTop, (bottom - container.clientHeight));
		}
	}
}

export function splitRelationNew(obj) {
	obj.relation = obj.relation || "";
	obj.aveg = obj.aveg || false;
	obj.relativeFlag = obj.relativeFlag || 1;
	obj.relKeyword = obj.relKeyword || "";
	const {
		relation, aveg, relKeyword, relativeFlag
	} = obj;
	
	let wholeRelationKeywords = []
	if(relativeFlag == 2) {
		let spouseRelationKeywords = ["配", "赘", "室", "娶", "聘", "妣", "适"]; // 两个字取第二个字
		if(relKeyword.indexOf("继") > 0) {
			spouseRelationKeywords.push("继");
		}
		wholeRelationKeywords = [
			// relKeyword,
			...spouseRelationKeywords,
		]
	} else {
		wholeRelationKeywords = [
			// "后裔",
			"弟",
			// "始祖",
			// "初祖",
			// "脉祖",
			relKeyword,
		]
	}
	let pattern = new RegExp(wholeRelationKeywords.join("|"), 'g');
	
	let result = relation ? (relation.length > 5 && aveg ?  strToArr({
			str: relation,
			size: Math.ceil(relation.length / 2),
			once: true
		}) : relation.split(pattern)) : [];
	if (result.length > 1 && result[0]) {
		return [
			result[0],
			relation.replace(result[0], "")
		]
	}
	return [relation];
}
export function splitRelation(obj) {
	obj.relativeFlag = obj.relativeFlag || 1;
	obj.aveg = obj.aveg || false;
	obj.showType = obj.showType || 1;
	const {
		relation, relativeFlag, aveg, showType,
	} = obj;
	if (relation) {
		let sonRelationKeywords = ["长子", "次子", "继子", "之子", "赘子", "抚子", "祧子", "嗣子", "三子", "四子", "五子", "六子", "七子", "八子",
				"九子", "十子", "十一子", "十二子", "十三子", "十四子", "十五子", "孪子", "之裔"
			],
			daughterRelationKeywords = ["长女", "次女", "继女", "之女", "抚女", "祧女", "嗣女", "三女", "四女", "五女", "六女", "七女", "八女",
				"九女", "十女", "十一女", "十二女", "十三女", "十四女", "十五女"
			],
			grandsonRelationKeywords = ["长孙", "次孙", "继孙", "之孙", "外孙", "抚孙", "祧孙", "嗣孙", "曾孙", "玄孙"],
			granddaughterRelationKeywords = ["长孙女", "次孙女", "继孙女", "孙女", "之孙女", "外孙女", "抚孙女", "祧孙女", "嗣孙女", "曾孙女", "玄孙女"],
			spouseRelationKeywords = ["配", "赘", "室", "娶", "聘", "妣"]; // 两个字取第二个字
		let wholeRelationKeywords = [];
		
		if(relativeFlag == 2) {
			if(showType == BOOK_TYPE_DOCUMENT) {
				wholeRelationKeywords = [
					...spouseRelationKeywords,
				]
			}
		} else {
			wholeRelationKeywords = [
				...sonRelationKeywords,
				...daughterRelationKeywords,
				...grandsonRelationKeywords,
				...granddaughterRelationKeywords,
				"后裔",
				"弟",
				"始祖",
				"初祖",
				"脉祖"
			]
		}

		// 使用正则表达式构建包含关键字的模式
		let pattern = new RegExp(wholeRelationKeywords.join("|"), 'g');
		
		// 对字符串进行切割操作
		let result = relation.length > 5 && aveg ? strToArr({
			str: relation,
			size: Math.ceil(relation.length / 2),
			once: true
		}) : (wholeRelationKeywords.length ? relation.split(pattern) : []);
		if (result.length > 1) {
			return [
				result[0],
				relation.replace(result[0], "")
			]
		}
		return [relation];
	}
	return "";
}

/**
 * 家族成员排序
 * displayFlag：1全部显示2世系图3齿录4全隐藏
 * 排序规则：generationNum/gender/ranking/orderNo/id
 * @param {Object} 
 */
export function orderNodes(obj) {
	obj.nodes = obj.nodes || []
	obj.displayFlagList = obj.displayFlagList || [1,2]
	obj.withSpouse = obj.withSpouse || 0
	obj.startGeneration = obj.startGeneration || 1
	obj.endGeneration = obj.endGeneration || 5
	let {
		nodes, displayFlagList, withSpouse, startGeneration, endGeneration
	} = obj;
	
	let wholeNodeIds = [], wholeNodes = [], sortResult, relativeNodes = {}, tempRelativeNodeList = [], relativeNodeList = [], tmpSubNodeList = [];
	
	nodes.sort((a, b) => {
		return a.generationNum - b.generationNum;
	})
	nodes.forEach(node => {
		wholeNodeIds.push(node.id);
		node.generationNum = node.generationNum || 1;
		wholeNodes[node.id] = node;
		if(node.relativeFlag == 1 && displayFlagList.includes(node.displayFlag) && node.generationNum >= startGeneration && node.generationNum <= endGeneration) {
			relativeNodes[node.id] = node;
		}
	})
	
	Object.values(relativeNodes).forEach(node => {
		node.subNodeList = node.subNodeList || []
		if (node.subNodeList.length) {
			tmpSubNodeList = node.subNodeList.filter(subNode => {
				// return wholeNodeIds.includes(subNode.id) && displayFlagList.includes(relativeNodes[subNode.id].displayFlag) && subNode.generation >= startGeneration && subNode.generation <= endGeneration
				return displayFlagList.includes(subNode.displayFlag)
			});
			node.subNodeList = tmpSubNodeList.sort((a, b) => {
				sortResult = a.gender - b.gender;
				if (sortResult == 0) {
					sortResult = a.ranking - b.ranking;
					if (sortResult == 0) {
						sortResult = a.orderNo - b.orderNo;
						if (sortResult == 0) {
							sortResult = a.id - b.id;
						}
					}
				}
				return sortResult;
			}).concat();
			relativeNodes[node.id] = node;
		}
		
		node.spouseNodeList = node.spouseNodeList || [];
		if(withSpouse && node.spouseNodeList.length) {
			node.spouseNodeList = node.spouseNodeList.sort((a, b) => {
				sortResult = a.ranking - b.ranking;
				if (sortResult == 0) {
					sortResult = a.orderNo - b.orderNo;
					if (sortResult == 0) {
						sortResult = a.id - b.id;
					}
				}
				return sortResult;
			}).concat();
		}
	});
	tempRelativeNodeList = Object.values(relativeNodes);
	let ancenstors = minArrayEles({
		data: tempRelativeNodeList,
		valueKey: "generationNum",
		targetKey: "relativeFlag",
		targetValue: 1,
	});
	
	let wholeRelatives = [];
	function makeRelativeOrder(tmpNode) {
		wholeRelatives.push(tmpNode);
		tmpNode.spouseNodeList = tmpNode.spouseNodeList || [];
		if(withSpouse && tmpNode.spouseNodeList.length) {
			tmpNode.spouseNodeList.forEach((spouse, sindex) => {
				wholeRelatives.push(wholeNodes[spouse.id]);
			});
		}
		tmpNode.subNodeList = tmpNode.subNodeList || [];
		if (tmpNode.subNodeList.length) {
			tmpNode.subNodeList.forEach((subnode, subindex) => {
				if(relativeNodes[subnode.id]) {
					makeRelativeOrder(relativeNodes[subnode.id]);
				}
			})
		}
	}
	
	ancenstors.forEach(ancenstor => {
		makeRelativeOrder(ancenstor);
	});
	return wholeRelatives;
}

/**
 * @param {Object} 获取数组对象指定属性的最小值
 */
export function minArrayEles(obj) {
	obj.data = obj.data || [];
	obj.valueKey = obj.valueKey || "value";
	obj.targetKey = obj.targetKey || "";
	obj.targetValue = obj.targetValue || "";
	const {
		data, valueKey, targetKey, targetValue
	} = obj;
	
	let array = [];
	if(targetKey) {
		data.forEach(record => {
			if(record[targetKey] == targetValue) {
				array.push(record);
			}
		});
	} else {
		array = data.concat();
	}
	let minElements = [];
	let minElement = array.reduce((min, current) => {
		current[valueKey] = current[valueKey] || 0;
	  return min && min[valueKey] < current[valueKey] ? min : current;
	}, array[0]);
	array.forEach(node => {
		if(node[valueKey] == minElement[valueKey]) {
			minElements.push(node);
		}
	});
	return minElements;
}

// 毫米转像素
export function mm2px(num, dpi) {
	dpi = dpi || 300;
	return (num * dpi / 25.4).toFixed(2);
}