import cookie from 'js-cookie'

import { storage, TOKEN_TABLE_NAME } from "@/config"
/**
 * @description 获取token
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getToken() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.getItem(TOKEN_TABLE_NAME)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.getItem(TOKEN_TABLE_NAME)
    } else if ('cookie' === storage) {
      return cookie.get(TOKEN_TABLE_NAME)
    } else {
      return localStorage.getItem(TOKEN_TABLE_NAME)
    }
  } else {
    return localStorage.getItem(TOKEN_TABLE_NAME)
  }
}

/**
 * @description 存储token
 * @param token
 * @returns {void|*}
 */
export function setToken(token) {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.setItem(TOKEN_TABLE_NAME, token)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.setItem(TOKEN_TABLE_NAME, token)
    } else if ('cookie' === storage) {
      return cookie.set(TOKEN_TABLE_NAME, token)
    } else {
      return localStorage.setItem(TOKEN_TABLE_NAME, token)
    }
  } else {
    return localStorage.setItem(TOKEN_TABLE_NAME, token)
  }
}

/**
 * @description 移除token
 * @returns {void|Promise<void>}
 */
export function removeToken() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.removeItem(TOKEN_TABLE_NAME)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.clear()
    } else if ('cookie' === storage) {
      return cookie.remove(TOKEN_TABLE_NAME)
    } else {
      return localStorage.removeItem(TOKEN_TABLE_NAME)
    }
  } else {
    return localStorage.removeItem(TOKEN_TABLE_NAME)
  }
}
