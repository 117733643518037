/**
  * @Description: 本地存储器localStorage/sessionStorage
  * @author Ouyang Jeff(1014466231@qq.com)
  * @create
  * @modify Ouyang Jeff(1014466231@qq.com)
  * @update
  */
import {
	SESSION_STORAGE,
	LOCAL_STORAGE,
	
	USER_INFO_KEY,
	BOOK_INFO_KEY,
	CONTINUATION_INFO_KEY,
	FAMILY_INFO_KEY,
	
	FAMILY_BOOKS_KEY,
	BOOK_VOLUMES_KEY,
} from "@/config"

// 获取当前用户信息
export function getUser() {
  return getStorage(USER_INFO_KEY, "{}", LOCAL_STORAGE)
}

// 缓存当前用户信息
export function setUser(userInfo) {
  setStorage(USER_INFO_KEY, userInfo, LOCAL_STORAGE)
}

// 获取当前家族信息
export function getFamily() {
  return getStorage(FAMILY_INFO_KEY, "{}", LOCAL_STORAGE)
}

// 缓存当前家族信息
export function setFamily(familyInfo) {
  setStorage(FAMILY_INFO_KEY, familyInfo, LOCAL_STORAGE)
}

// 获取当前家族族谱信息
export function getFamilyBooks() {
  return getStorage(FAMILY_BOOKS_KEY, "[]", LOCAL_STORAGE)
}

// 缓存当前家族族谱信息
export function setFamilyBooks(familyBooks) {
  setStorage(FAMILY_BOOKS_KEY, familyBooks, LOCAL_STORAGE)
}

// 获取当前族谱信息
export function getBook() {
  return getStorage(BOOK_INFO_KEY, "{}", LOCAL_STORAGE)
}

// 缓存当前族谱信息
export function setBook(bookInfo) {
  setStorage(BOOK_INFO_KEY, bookInfo, LOCAL_STORAGE)
}

// 获取当前族谱卷列表集
export function getBookVolumes() {
  return getStorage(BOOK_VOLUMES_KEY, "[]", LOCAL_STORAGE)
}

// 缓存当前族谱卷列表集
export function setBookVolumes(volumes) {
  setStorage(BOOK_VOLUMES_KEY, volumes, LOCAL_STORAGE)
}

// 获取当前续谱信息采集信息
export function getContinuation() {
  return getStorage(CONTINUATION_INFO_KEY, "{}", LOCAL_STORAGE)
}

// 缓存当前续谱信息采集信息
export function setContinuation(bookInfo) {
  setStorage(CONTINUATION_INFO_KEY, bookInfo, LOCAL_STORAGE)
}

/**
 * 从localStorage/sessionStorage里获取缓存数据
 * @param {Object} key 缓存的key
 * @param {Object} defaultValue 默认缺省值，json字符串
 * @param {Object} storageType 缓存类型，1 sessionStorage 2 localStorage
 */
export function getStorage(key, defaultValue, storageType) {
  storageType = storageType || SESSION_STORAGE
  defaultValue = defaultValue == undefined ? "" : defaultValue
  let arg = storageType == LOCAL_STORAGE ? localStorage.getItem(key) : sessionStorage.getItem(key)
  return JSON.parse(arg || defaultValue)
}

// 往存储localStorage/sessionStorage里缓存数据
export function setStorage(key, value, storageType) {
  storageType = storageType || SESSION_STORAGE
  value = value != undefined ? value : ""
  if(typeof value == "object") {
    value = JSON.stringify(value)
  }
  if(storageType == LOCAL_STORAGE) {
    localStorage.setItem(key, value)
  } else {
    sessionStorage.setItem(key, value)
  }
}

// 清空本地缓存
export function removeStorage(key, storageType) {
	storageType = storageType || SESSION_STORAGE
	if(storageType == LOCAL_STORAGE) {
	  localStorage.removeItem(key)
	} else {
	  sessionStorage.removeItem(key)
	}
}