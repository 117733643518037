import request from '@/utils/request'
import API_KEY from '@/service/apiKey'
import {
	defaultTimeout
} from "@/config"

// 所有(一次性全部传输回来，没有分页)
export function getAll(obj) {
	let tmpModuleName, tmpParams, tmpUrl, tmpUrlKey, tmpBaseUrl;
	tmpModuleName = obj.moduleName
	tmpParams = obj.data || {}
	tmpUrlKey = obj.urlKey || ""
	tmpUrl = obj.url || API_KEY[tmpUrlKey] || ""
	tmpBaseUrl = obj.baseUrl || API_KEY[obj.baseUrlKey]
	return request({
		baseURL: tmpBaseUrl || "",
		url: tmpUrl ? tmpUrl : API_KEY[tmpModuleName],

		method: obj.method || "get",
		data: obj.method != "get" ? tmpParams : {},
		params: obj.method != "post" ? tmpParams : {},
	})
}

// 列表/查询（有分页）
export function getList(obj) {
	let tmpModuleName, tmpParams, tmpUrl, tmpUrlKey, tmpBaseUrl;
	tmpModuleName = obj.moduleName
	tmpParams = obj.data || {}
	tmpUrlKey = obj.urlKey || ""
	tmpUrl = obj.url || API_KEY[tmpUrlKey] || ""
	tmpBaseUrl = obj.baseUrl || API_KEY[obj.baseUrlKey]
	return request({
		baseURL: tmpBaseUrl || "",
		url: tmpUrl ? tmpUrl : API_KEY[tmpModuleName],

		method: obj.method || "get",
		data: obj.method != "get" ? tmpParams : {},
		params: obj.method != "post" ? tmpParams : {},
	})
}

// 详情
export function getDetail(obj) {
	let tmpModuleName, tmpParams, tmpUrl, tmpUrlKey, tmpBaseUrl;
	tmpModuleName = obj.moduleName
	tmpParams = obj.data || {}
	tmpUrlKey = obj.urlKey || ""
	tmpUrl = obj.url || API_KEY[tmpUrlKey] || ""
	tmpBaseUrl = obj.baseUrl || API_KEY[obj.baseUrlKey]
	return request({
		baseURL: tmpBaseUrl || "",
		url: tmpUrl ? tmpUrl : API_KEY[tmpModuleName],
		method: obj.method || "get",
		data: obj.method != "get" ? tmpParams : {},
		params: obj.method != "post" ? tmpParams : {},
	})
}

// 新增
export function doAdd(obj) {
	let tmpModuleName, tmpData, tmpUrl, tmpUrlKey, tmpBaseUrl;
	tmpModuleName = obj.moduleName
	tmpData = obj.data || {}
	tmpUrlKey = obj.urlKey || ""
	tmpUrl = obj.url || API_KEY[tmpUrlKey] || ""
	tmpBaseUrl = obj.baseUrl || API_KEY[obj.baseUrlKey]
	return request({
		baseURL: tmpBaseUrl || "",
		url: tmpUrl ? tmpUrl : API_KEY[tmpModuleName],

		method: obj.method || "post",
		data: obj.method != "get" ? tmpData : {},
		params: obj.method != "post" ? tmpData : {},

		btn_func_code: tmpModuleName + "_ADD",
		btn_func_name: "添加",
	})
}

// 编辑
export function doEdit(obj) {
	let tmpModuleName, tmpData, tmpUrl, tmpUrlKey, tmpBaseUrl;
	tmpModuleName = obj.moduleName
	tmpData = obj.data || {}
	tmpUrlKey = obj.urlKey || ""
	tmpUrl = obj.url || API_KEY[tmpUrlKey] || ""
	tmpBaseUrl = obj.baseUrl || API_KEY[obj.baseUrlKey]
	return request({
		baseURL: tmpBaseUrl || "",
		url: tmpUrl ? tmpUrl : API_KEY[tmpModuleName],

		method: obj.method || "put",
		data: obj.method != "get" ? tmpData : {},
		params: obj.method != "put" ? tmpData : {},

		btn_func_code: tmpModuleName + "_EDIT",
		btn_func_name: "编辑",
	})
}

// 删除
export function doDelete(obj) {
	let tmpModuleName, tmpDirectly, tmpData, tmpUrl, tmpUrlKey, tmpBaseUrl;
	tmpModuleName = obj.moduleName
	tmpData = obj.data || {}
	tmpUrlKey = obj.urlKey || ""
	tmpUrl = obj.url || API_KEY[tmpUrlKey] || ""
	tmpBaseUrl = obj.baseUrl || API_KEY[obj.baseUrlKey]
	return request({
		baseURL: tmpBaseUrl || "",
		url: tmpUrl ? tmpUrl : API_KEY[tmpModuleName],

		method: obj.method || "delete",
		data: obj.method != "get" ? tmpData : {},
		params: obj.method != "delete" ? tmpData : {},

		btn_func_code: tmpModuleName + "_DELETE",
		btn_func_name: "删除",
	})
}

// 导入
export function doImport(obj) {
	let tmpModuleName, tmpDirectly, tmpData, tmpUrl, tmpUrlKey, tmpBaseUrl;
	tmpModuleName = obj.moduleName
	tmpData = obj.data || {}
	tmpUrlKey = obj.urlKey || ""
	tmpUrl = obj.url || API_KEY[tmpUrlKey] || ""
	tmpBaseUrl = obj.baseUrl || API_KEY[obj.baseUrlKey]
	return request({
		baseURL: tmpBaseUrl || "",
		url: tmpUrl ? tmpUrl : (API_KEY[tmpModuleName] + '/import'),
		method: 'post',
		data: tmpData,
		btn_func_code: tmpModuleName + "_IMPORT",
		btn_func_name: "导入",
	})
}

// 导出
export function doExport(obj) {
	let tmpModuleName, tmpData, tmpUrl, tmpUrlKey, tmpBaseUrl;
	tmpModuleName = obj.moduleName
	tmpData = obj.data || {}
	tmpUrlKey = obj.urlKey || ""
	tmpUrl = obj.url || API_KEY[tmpUrlKey] || ""
	tmpBaseUrl = obj.baseUrl || API_KEY[obj.baseUrlKey]
	return request({
		baseURL: tmpBaseUrl || "",
		url: tmpUrl ? tmpUrl : (API_KEY[tmpModuleName] + '/export'),
		method: 'post',
		data: tmpData,
		btn_func_code: tmpModuleName + "_EXPORT",
		btn_func_name: "导出",
	})
}
// 根据路径和参数获取数据——通用的get请求
export function getSpecial(obj) {
	let tmpModuleName, tmpParams, tmpUrl, tmpUrlKey, tmpBaseUrl;
	tmpModuleName = obj.moduleName
	tmpParams = obj.params
	tmpUrl = obj.url
	tmpUrlKey = obj.urlKey
	tmpUrl = tmpUrl || API_KEY[tmpUrlKey] || ""
	tmpBaseUrl = obj.baseUrl || API_KEY[obj.baseUrlKey]
	
	let conf = {
		baseURL: tmpBaseUrl || "",
		url: tmpUrl ? tmpUrl : API_KEY[tmpModuleName],
		
		method: 'get',
		params: tmpParams,
	}
	if(obj.responseType) {
		conf.responseType = obj.responseType;
	}
	conf.timeout = obj.timeout || defaultTimeout;
	conf.responseDataKey = obj.responseDataKey || "";
	conf.subResponseDataKey = obj.subResponseDataKey || "";
	conf.defaultValue = obj.defaultValue || "";
	return request(conf)
	// return request.get(tmpUrl ? tmpUrl : API_KEY[tmpModuleName], conf)
}

// 根据路径和参数获取数据——通用的post请求
export function postSpecial(obj) {
	let tmpModuleName, tmpData, tmpUrl, tmpUrlKey, tmpBaseUrl;
	tmpModuleName = obj.moduleName
	tmpData = obj.data
	tmpUrl = obj.url
	tmpUrlKey = obj.urlKey
	tmpUrl = tmpUrl || API_KEY[tmpUrlKey] || ""
	tmpBaseUrl = obj.baseUrl || API_KEY[obj.baseUrlKey]
	
	let conf = {
		baseURL: tmpBaseUrl || "",
		url: tmpUrl ? tmpUrl : API_KEY[tmpModuleName],
		
		method: 'post',
		data: tmpData,
	}
	if(obj.responseType) {
		conf.responseType = obj.responseType;
	}
	conf.timeout = obj.timeout || defaultTimeout;
	conf.responseDataKey = obj.responseDataKey || "";
	conf.subResponseDataKey = obj.subResponseDataKey || "";
	conf.defaultValue = obj.defaultValue || "";
	return request(conf)
	// return request.post(tmpUrl ? tmpUrl : API_KEY[tmpModuleName], conf)
}

// 登录
export async function login(data, baseUrlKey, url) {
	url = url || ""
	if (!url) {
		url = API_KEY.USER_LOGIN
	}
	return request({
		baseURL: API_KEY[baseUrlKey],
		url: url,
		method: 'post',
		data,
	})
}

// 退出
export function logout() {
	return request({
		baseURL: API_KEY.USER_SERVER,
		url: '/logout',
		method: 'get',
	})
}

// 注册
export function register(data) {
	return request({
		baseURL: API_KEY.USER_SERVER,
		url: '/register',
		method: 'post',
		data,
	})
}

// 获取公钥
export function getPublicKey() {
	return request({
		url: '/publicKey',
		method: 'get',
	})
}

// 获取icon列表——框架专用
export function getIconList(params) {
	return request({
		url: '/remixIcon/getList',
		method: 'get',
		params,
	})
}

export function expireToken() {
	return request({
		url: '/expireToken',
		method: 'get',
	})
}

// 刷新token
export function refreshToken() {
	return request({
		url: '/refreshToken',
		method: 'get',
	})
}

// 获取用户可访问菜单集
export function getUserMenus(code) {
	let url = '/manager/func/user'
	code = code || ""
	if (code) {
		url += "?func_code=" + code
	}
	return request({
		url: url,
		method: 'get'
	})
}

// 获取用户可访问菜单集
export function loadDicts() {
	return request({
		url: '/manager/dict',
		method: 'get'
	})
}

export function getRouterList(params) {
  return request({
    url: '/router/getList',
    method: 'get',
    params,
  })
}

// 常规请求
export function normal(obj, id) {
	let tmpModuleName, tmpUrl, tmpUrlKey, tmpBaseUrl;
	tmpModuleName = obj.moduleName
	tmpUrlKey = obj.urlKey || ""
	tmpUrl = obj.url || API_KEY[tmpUrlKey] || ""
	tmpBaseUrl = obj.baseUrl || API_KEY[obj.baseUrlKey]

	let conf = {};
	conf.baseURL = tmpBaseUrl || "";
	conf.url = tmpUrl || API_KEY[tmpModuleName];
	if (obj.joinUrl) {
		conf.url += "/" + parseStrEmpty(obj.joinUrl);
	}
	if (obj.headers) {
		conf.headers = obj.headers;
	}
	conf.method = obj.method || "get";
	if (obj.data) {
		// todo 需要核查原因，为啥params不行
		if (obj.method == "get") {
			conf.params = obj.data;
		} else {
			conf.data = obj.data;
		}
		// conf.data = obj.data;
	}
	if (obj.method == "put" && id && !obj.joinUrl) {
		conf.url += `/${id}`
	}
	if (obj.responseType) {
		conf.responseType = obj.responseType;
	}
	conf.timeout = obj.timeout || defaultTimeout;
	conf.responseDataKey = obj.responseDataKey || "";
	conf.subResponseDataKey = obj.subResponseDataKey || "";
	conf.defaultValue = obj.defaultValue || "";
	return request(conf);
}
