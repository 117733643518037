<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
  	window.addEventListener("resize", this.resize);
  },
  methods: {
  	resize() {
  		// window.location.reload()
  	}
  },
  beforeDestroy() {
  	window.removeEventListener("resize", this.resize);
  }
}
</script>