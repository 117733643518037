import axios from 'axios'
import {
  contentType,
  debounce,
  messageName,
  defaultTimeout,
  statusName,
  successCode,
} from '@/config'
import router from '@/router'
import store from '@/store'
// import qs from 'qs'
import {
  isArray
} from '@/utils/validate'
import {
  addErrorLog,
  needErrorLog
} from '@/plugins/errorLog'
import {
  refreshToken
} from '@/service/defaultRequest'

import ApiKey from "@/service/apiKey"
import { Notify, Loading, Message } from "element-ui"

let loadingInstance

let refreshToking = false

let requests = []

// 操作正常Code数组
const codeVerificationArray = isArray(successCode) ? [...successCode] : [...[successCode]]

const CODE_MESSAGE = {
  200: '服务器成功返回请求数据',
  201: '新建或修改数据成功',
  202: '一个请求已经进入后台排队(异步任务)',
  204: '删除数据成功',
  313: '账号退出成功',
  400: '发出信息有误',
  401: '用户没有权限(令牌失效、用户名、密码错误、登录过期)',
  402: '令牌过期',
  403: '用户得到授权，但是访问是被禁止的',
  404: '访问资源不存在',
  406: '请求格式不可得',
  410: '请求资源被永久删除，且不会被看到',
  500: '服务器发生错误',
  502: '网关错误',
  503: '服务不可用，服务器暂时过载或维护',
  504: '网关超时',
}

/**
 * axios请求拦截器配置
 * @param config
 * @returns {any}
 */
const requestConf = (config) => {
  config.headers = config.headers || {
	'Content-Type': 'application/json'
  }
  config.baseURL = config.baseURL || ApiKey.ADMIN_SERVER
  const token = store.getters['user/token']

  // 不规范写法 可根据setting.config.js tokenName配置随意自定义headers
  // if (token) config.headers[tokenName] = token

  // 规范写法 不可随意自定义
  // if (token) config.headers['Authorization'] = `Bearer ${token}`

  /* if (
    config.data &&
    config.headers['Content-Type'] ===
    'application/x-www-form-urlencoded;charset=UTF-8'
  )
    config.data = qs.stringify(config.data) */
  if (debounce.some((item) => config.url.includes(item)))
    loadingInstance = Loading()
  config.withCredentials = true // 允许携带token ,这个是解决跨域产生的相关问题
  return config
}

/**
 * 刷新刷新令牌
 * @param config 过期请求配置
 * @returns {any} 返回结果
 */
const tryRefreshToken = async (config) => {
  if (!refreshToking) {
    refreshToking = true
    try {
      const {
        data: {
          token
        },
      } = await refreshToken()
      if (token) {
        store.dispatch('user/setToken', token).then(() => {})
        // 已经刷新了token，将所有队列中的请求进行重试
        requests.forEach((cb) => cb(token))
        requests = []
        return instance(requestConf(config))
      }
    } catch (error) {
      console.error('refreshToken error =>', error)
      router.push({
        path: '/login',
        query: {
          random: Math.random()
        },
        replace: true
      }).then(() => {})
    } finally {
      refreshToking = false
    }
  } else {
    return new Promise((resolve) => {
      // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
      requests.push(() => {
        resolve(instance(requestConf(config)))
      })
    })
  }
}

/**
 * axios响应拦截器
 * @param config 请求配置
 * @param data response数据
 * @param status HTTP status
 * @param statusText HTTP status text
 * @returns {Promise<*|*>}
 */
const handleData = async (response) => {
	let {
	  config,
	  data,
	  status,
	  headers,
	  statusText
	} = response
	config.defaultValue = config.defaultValue || "";
  if (loadingInstance) loadingInstance.close()
  // 若data.code存在，覆盖默认code
  let code = data && data[statusName] != undefined ? data[statusName] : status
  // 若code属于操作正常code，则status修改为200
  if (codeVerificationArray.indexOf(data[statusName]) > -1) code = 200
  switch (parseInt(code)) {
    case 200:
      // 业务层级错误处理，以下是假定restful有一套统一输出格式(指不管成功与否都有相应的数据格式)情况下进行处理
      // 例如响应内容：
      // 错误内容：{ code: 1, msg: '非法参数' }
      // 正确内容：{ code: 200, data: {  }, msg: '操作正常' }
      // return data
	  if(headers && headers['content-type'] && headers['content-type'] == "application/octet-streem") {
		  try {
			  var contentDisposition = decodeURI(headers["content-disposition"]);
			  /**
			   * filename：字面字符串"filename"。
			   * \s*=\s*：匹配0个或多个空白字符，紧跟着'='，然后是0个或多个空白字符。
			   * ([^;]+)：匹配非分号（;）字符的一个或多个字符，这些字符被捕获到一个分组中。
			   */
			  var fileRegex = /filename\s*=\s*([^;]+)/;
			  var result = contentDisposition.match(fileRegex);
			  var fileName = config.defaultValue ? config.defaultValue : (result.length ? result[1] : ("下载文件" + '.xlsx'));
			  
			  var blob = new Blob([data]);
			  var url = window.URL.createObjectURL(blob); // 创建 url 并指向 blob
			  var a = document.createElement('a');
			  a.href = url;
			  a.download = fileName;
			  a.click();
			  window.URL.revokeObjectURL(url); // 释放该 url
			  return true;
		  } catch(e) {
			  console.log(e);
		  }
	  }
      return config.responseDataKey ? (config.subResponseDataKey ? (data[config.responseDataKey][config.subResponseDataKey] || config.defaultValue) : (data[config.responseDataKey] || config.defaultValue)) : data;
    case 302:
    case 311:
    case 312:
    case 313:
    case 314:
    case 315:
    case 401:
      store
        .dispatch('user/resetAll')
        .then(() => {
          router.push({
            path: '/login',
            query: {
              random: Math.random()
            },
            replace: true
          }).then(() => {})
        })
      break
    case 402:
      return await tryRefreshToken(config)
    case 403:
      router.push({
        path: '/403'
      }).then(() => {})
      break
  }
  // 异常处理
  // 若data.msg存在，覆盖默认提醒消息
  const errMsg = `${data && data[messageName]
      ? data[messageName]
      : CODE_MESSAGE[code]
        ? CODE_MESSAGE[code]
        : statusText
    }`
  // 是否显示高亮错误(与errorHandler钩子触发逻辑一致)
  if (code != "313") {
    Message({
		message: errMsg,
		type: 'error'
	})
    if (needErrorLog())
      addErrorLog({
        message: errMsg,
        stack: data,
        isRequest: true
      })
  }
  return Promise.reject(data)
}

/**
 * @description axios初始化
 */
const instance = axios.create({
  timeout: defaultTimeout,
  /* headers: {
      'Content-Type': contentType,

      'Access-Control-Allow-Origin': "*",

  }, */
  headers: {
    'Content-Type': 'application/json'
  }
})

/**
 * @description axios请求拦截器
 */
instance.interceptors.request.use(requestConf, (error) => {
  return Promise.reject(error)
})

/**
 * @description axios响应拦截器
 */
instance.interceptors.response.use(
  (response) => handleData(response),
  (error) => {
    if (loadingInstance) loadingInstance.close()
    const {
      response
    } = error
    if (response === undefined) {
      Message({
		message: '未可知错误，可能是因为后端不支持跨域CORS、接口地址不存在等问题引起',
        type: 'error',
	  })
      return {}
    } else return handleData(response)
  }
)

export default instance
