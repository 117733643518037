/**
 * @description 系统常量定义与业务个性化设置
 */
module.exports = {
	"SESSION_STORAGE": 1, // 信息本地存储类型sessionStorage
	"LOCAL_STORAGE": 2, // 信息本地存储类型localStorage
	
	
	// token在localStorage、sessionStorage、cookie存储的key的名称
	"TOKEN_TABLE_NAME": 'TOKEN',
	// vuex持久化存储的key名
	"STORAGE_KEY": "ADMIN_STORE",
	// 是否需要修改初始化密码key名
	"NEED_RESET_FLAG_KEY": "NEED_RESET_PWD",
	// 用户信息存储key名
	"USER_INFO_KEY": "USER_INFO",
	// 谱书信息存储key名
	"BOOK_INFO_KEY": "BOOK_INFO",
	// 续谱信息存储key名
	"CONTINUATION_INFO_KEY": "CONTINUATION_INFO",
	// 家族信息存储key名
	"FAMILY_INFO_KEY": "FAMILY_INFO",
	// 家族族谱集合存储key名
	"FAMILY_BOOKS_KEY": "FAMILY_BOOKS",
	
	"INTERVAL_PIE_TIME": 3000, // 定时任务切换时长——饼状图，3s——便于观察
	// 表格相关
	"PAGE_SIZE": 20, // 分页大小
	"LARGE_PAGE_SIZE": 200, // 分页大小
	"TABLE_PADDING_HEIGHT": 210, // 表格填充高度210
	"TABLE_HEADER_HEIGHT": 37, // 表格头部高度35
	"TABLE_ROW_HEIGHT": 45, // 表格单行高度60
	"TABLE_MIN_HEIGHT": 200, // 表格最小高度200
	"SEARCH_COLUMN_WIDTH": 6, // 搜索表单组件默认宽度
	"SINGLE_DOUBLE_TIME": 500, // 单双击间隔时长，毫秒级
	// 表单相关
	"FORM_PADDING_HEIGHT": 210, // 表单填充高度210
	"WHOLE_COLUMNS_INLINE": 24, // 每行的栅栏数量
	"FORM_COLUMN_WIDTH": 6, // 表单组件默认宽度
	// 详情页相关
	"DETAIL_PADDING_HEIGHT": 210, // 页面填充高度210
	"DETAIL_COLUMN_WIDTH": 6, // 表单组件默认宽度
	// 树相关
	"TREE_SIZE": 1000, // 树组件数据量大小
	
	"SUCCESS_CODE": [1], // 请求成功后的返回结果
	
	
	/***************************************** 谱书排版业务专属配置 *******************************************************/
	"BOOK_DEFAULT_DPI": 300, // 默认精度
	
	// 纸张类型
	"BOOK_PAGE_SIZE_TYPE_A4": "1", // A4， 尺寸：210*297
	"BOOK_PAGE_SIZE_TYPE_A3": "2", // A3， 尺寸：420*297
	
	// 成谱类型
	"BOOK_CONTENT_TYPE_WHOLE": "1", // 总谱
	"BOOK_CONTENT_TYPE_BRANCH": "2", // 支谱
	
	// 族谱类型：1牒纪式 2欧式 3苏式 4现代横版，默认是1
	"BOOK_TYPE_DOCUMENT": "1", // 牒纪式
	"BOOK_TYPE_OU": "2", // 欧式
	"BOOK_TYPE_SU": "3", // 苏式
	"BOOK_TYPE_CONTENT": "4", // 现代横版
	
	// 排版类型
	"BOOK_PAGE_TYPE_SINGLE": "1", // 单页
	"BOOK_PAGE_TYPE_DOUBLE": "2", // 筒子页

	"BOOK_PAGE_IMAGE_WIDTH": 360, // 图片宽度（即最终生成的图片宽度），单位mm
	"BOOK_PAGE_IMAGE_HEIGHT": 297, // 图片高度（即最终生成的图片宽度），单位mm
	"BOOK_PAGE_IMAGE_TOP": 33, // 齿录头部距离，单位mm
	"BOOK_PAGE_IMAGE_BOTTOM": 16.5, // 齿录底部距离，单位mm
	"BOOK_PAGE_IMAGE_LEFT": 33, // 齿录左侧距离，单位mm
	
	"BOOK_PAGE_IMAGE_OUTLINE_WIDTH": 1.35, // 外线宽度，单位mm
	"BOOK_PAGE_IMAGE_LINESPACING": 1.35, // 线间距，单位mm
	"BOOK_PAGE_IMAGE_INLINE_WIDTH": 1, // 内线宽度，单位mm
	
	"BOOK_SPINE_WIDTH": 21, // 书脊宽度，单位mm
	
	"BOOK_CONTENT_DISPATCH": 1, // 谱书齿录cm_content
	"BOOK_CONTENT_DISPATCH_FINAL": 2, // 谱书齿录cm_content_final
	"BOOK_CONTENT_CONTINUATION": 3, // 续谱信息采集三段式齿录temp_household_member
	"BOOK_CONTENT_FAMILY_MEMBER": 4, // 家族成员齿录family_member
	
	// 校对模式
	"PROOF_STEP_VIEW": 1, // 查看/打印，高度还原A3尺寸，且支持order/id的显示和隐藏，方便校对后的修正，默认双页——预留间隙
	"PROOF_STEP_CHECK": 2, // 校对模式-仅查阅，无操作按钮——校对不要有order/id——单页无滚动
	"PROOF_STEP_VARIABLE": 3, // 增减模式-可增减列、可增删字、可换行——todo合并行待实现——单页+滚动
	"PROOF_STEP_REPLACE": 4, // 替换模式—仅支持修改文字——更改错别字、加样式、更换生僻字——单页+滚动
	"PROOF_STEP_FORMAL": 5, // 查看/打印，高度还原A3尺寸，定稿，默认双页
	
	// 五世一表设置
	"PRINTTREE_COLUMN_SIZE": 16, // 每页五世一表大列
	"PRINTTREE_BRANCH": 30, // 分支名字体大小
	"PRINTTREE_GENERATION": 13, // 世代字体大小
	"PRINTTREE_NAME": 17, // 内容字体大小
	"PRINTTREE_RELATION": 8, // 关系字体大小
	// 齿录设置
	// 牒记式
	// 5-3-30，卷名宋体，分支名宋体，世代宋体
	"PATCH_COLUMN_SIZE": 5, // 每页齿录大列
	"COLUMN_ITEM_SIZE": 3, // 每大列包含的小列
	"COLUMN_ITEM_LENGTH": 30, // 每小列包含的字符数
	"FONT_BACK": "black", // 黑体
	"FONT_LI": "li", // 隶书
	"FONT_SONG": "song", // 宋体
	"FONT_SONG_FZDBS": "dbs", // 方正大标宋
	"FONT_SONG_FZXBS": "xbs", // 方正小标宋
	"FONT_SONG_FZCJLJW": "cjl", // 方正粗金陵
	"FONT_SONG": "song", // 宋体
	"FONT_STANDARD": "standard", // 楷体
	"FONTSIZE_VOLUME": 30, // 卷名字体大小：5/6:30,9:18
	"FONTSIZE_BRANCH": 30, // 分支名字体大小：5/6:30,9:18
	"FONTSIZE_GENERATION": 28, // 世代字体大小：5/6:30,9:16
	"FONTSIZE_RELATION": 13, // 关系字体大小
	"FONTSIZE_NAME": 17, // 姓名字体大小
	"FONTSIZE_CONTENT": 13, // 内容字体大小
	"CONTENT_PADDING": 6, // 齿录边距
	// 现代
	"PATCH_ROW_SIZE": 25, // 每页行数
	"PATCH_COLUMN_LENGTH": 30, // 每行包含的字符数
	
	// 筒子页书脊
	"FONTSIZE_BOOK_NAME": 100, // 谱名字体大小 100,112
	"FONTSIZE_FAMILY_TITLE": 100, // 堂号字体大小 100,112
	
	// 齿录类型
	"DISPATCH_TYPE_EMPTY": 0, // 空齿录
	"DISPATCH_TYPE_INFO": 1, // 人员信息
	"DISPATCH_TYPE_GENERATION": 2, // 派名
	"DISPATCH_TYPE_BRANCH": 3, // 分支名
	"DISPATCH_TYPE_VOLUME": 4, // 卷名
	"DISPATCH_TYPE_NOTE": 5, // 备注说明
	
	"CHAR_SUBSTR_SPECIAL": ["）", "」", ")", "》", "。", "，", "！", "？", "、", "”", "："], // 长度分割时，不能放置到行首的字符集——需要前移到上一行
	"CHAR_RESOLVE": ['（', '）', '「', '」', '(', ')', '《', '》'], // 需要在古籍展示里面旋转的字符集
	
	"SUB_LOWERSIZE": 1, // 附属信息比主信息降格处理的字数
	
	"SPECIAL_WORD_STYLE": "s", // 样式字占位符
	"SPECIAL_WORD_RARELY": "r", // 生僻字占位符
	
	// 节点类型
	"MEMBER_TYPE_BLOOD": 1, // 亲缘节点
	"MEMBER_TYPE_MARRIAGE": 2, // 姻缘节点
	
	// 世系图排版方式
	"FAMILY_TREE_FIVE_TABLE": "1", // 按照五世横向切割五世一表
	"FAMILY_TREE_SUBFAMILY": "2", // 按照分支纵向到底切割五世一表
	
	// 内容类型
	"CONTENT_TYPE_DISPATCH": '4', // 齿录
	"CONTENT_TYPE_PRINTLINE": '5', // 世系图
	
	// 世系图结束世代后裔展示方式
	"SHOW_SON_TYPE_COUNT": "1", // 后裔数量
	"SHOW_SON_TYPE_NAME": "2", // 后裔单名
	
	/***************************************** 谱书排版业务专属配置 *******************************************************/
}