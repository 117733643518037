/**
 * @description router全局配置，如有必要可分文件抽离，其中asyncRoutes只有在intelligence模式下才会用到，pro版只支持remixIcon图标，具体配置请查看vip群文档
 */
import {
	publicPath,
	routerMode
} from '@/config'
import Layout from '@/components/Layouts/index.vue'

import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// 所有项目公用的路径
export const constantRoutes = [{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/basic/login'),
		meta: {
			hidden: true,
		},
	}, {
		path: '/register',
		name: 'Register',
		component: () => import('@/views/basic/register'),
		meta: {
			hidden: true,
		},
	},
	{
		path: '/callback',
		name: 'Callback',
		component: () => import('@/views/basic/callback'),
		meta: {
			hidden: true,
		},
	}, {
		path: '/403',
		name: '403',
		component: () => import('@/views/basic/403'),
		meta: {
			hidden: true,
		},
	}, {
		path: '/404',
		name: '404',
		component: () => import('@/views/basic/404'),
		meta: {
			hidden: true,
		},
	}, {
		path: '/resetpwd',
		name: 'Resetpwd',
		component: () => import('@/views/basic/resetpwd'),
		meta: {
			hidden: true,
		},
	}, {
		path: '/profile',
		name: 'Profile',
		component: () => import('@/views/basic/profile'),
		meta: {
			hidden: true,
		},
	}, {
		path: '/',
		name: 'Homepage',
		// component: Layout,
		component: () =>
			import('@/views/basic/index'),
		meta: {
			title: '首页',
			icon: 'home-2-line',
			// levelHidden: true,
		},
		/* children: [{
			path: '',
			name: 'Homepage',
			component: () =>
				import('@/views/basic/index'),
			meta: {
				title: '首页',
			},
		}, ] */
	},
]

// 谱书相关功能页路径
export const bookRoutes = [{
		path: '/book',
		name: 'Book',
		component: Layout,
		meta: {
			title: '谱书管理',
			icon: 'home-2-line',
			levelHidden: true,
		},
		children: [{
			path: 'index',
			name: 'VolumeList',
			component: () =>
				import('@/views/book/formal/index'),
			meta: {
				title: '谱书管理',
			},
		}]
	}, {
		path: '/bookImage',
		name: 'BookImage',
		component: Layout,
		meta: {
			title: '图片管理',
			icon: 'home-2-line',
		},
		children: [{
			path: 'index',
			name: 'BookImageList',
			component: () =>
				import('@/views/book/image/index'),
			meta: {
				title: '图片上传',
			},
		}, ]
	},
	{
		path: '/bookContent',
		name: 'BookContent',
		component: Layout,
		meta: {
			title: '内容管理',
			icon: 'home-2-line',
		},
		children: [{
			path: 'content',
			name: 'BookContentList',
			component: () =>
				import('@/views/book/content/index'),
			meta: {
				title: '齿录管理',
			},
		}, ]
	}, {
		path: '/bookProof',
		name: 'BookProof',
		component: Layout,
		meta: {
			title: '齿录校对',
			icon: 'home-2-line',
		},
		children: [
			{
				path: 'dispatch',
				name: 'ProofDispatch',
				component: () =>
					import('@/views/book/proof/dispatch'),
				meta: {
					title: '齿录校对',
				},
			},
			{
				path: 'dispatchEdit',
				name: 'ProofDispatchEdit',
				component: () =>
					import('@/views/book/proof/dispatchEdit'),
				meta: {
					title: '齿录编辑——增减改错',
				},
			},
			/* {
				path: 'dispatchReplace',
				name: 'ProofDispatchReplace',
				component: () =>
					import('@/views/book/proof/dispatchReplace'),
				meta: {
					title: '齿录编辑——替换',
				},
			}, */
		]
	},
	{
		path: '/typesetting',
		name: 'Typesetting',
		component: Layout,
		meta: {
			title: '排版',
			icon: 'home-2-line',
		},
		children: [
			/* {
				path: 'book',
				name: 'TypesettingBook',
				component: () =>
					import('@/views/book/typesettingBook/index'),
				meta: {
					title: '老谱重排',
				},
			}, */
			{
				path: 'family',
				name: 'TypesettingFamily',
				component: () =>
					import('@/views/book/typesettingFamily/index'),
				meta: {
					title: '续谱预排',
				},
			},
			{
				path: 'empty',
				name: 'TypesettingEmpty',
				component: () =>
					import('@/views/book/typesetting/empty'),
				meta: {
					title: '排版空页面',
				},
			},
			{
				path: 'mysql',
				name: 'TypesettingMysql',
				component: () =>
					import('@/views/book/typesetting/mysql'),
				meta: {
					title: '年代sql',
				},
			},
		]
	},
]

// 续谱相关功能页路径
export const continuationRoutes = [
	{
		path: '/continuation',
		name: 'ContinuationRoot',
		component: Layout,
		meta: {
			title: '分支与家庭',
			icon: 'home-2-line',
		},
		children: [
			{
				path: 'index',
				name: 'SubfamilyList',
				component: () =>
					import('@/views/continuation/enter/index'),
				meta: {
					title: '分支与家庭',
				},
			},
			{
				path: 'detail',
				name: 'SubfamilyDetail',
				component: () =>
					import('@/views/continuation/enter/detail'),
				meta: {
					title: '分支详情',
					hidden: true,
				},
			},
		]
	},
	{
		path: '/collectImage',
		name: 'CollectImageRoot',
		component: Layout,
		meta: {
			title: '图片管理',
			icon: 'home-2-line',
		},
		children: [
			{
				path: 'index',
				name: 'CollectImageList',
				component: () =>
					import('@/views/continuation/image/index'),
				meta: {
					title: '图片上传',
				},
			},
			{
				path: 'detail',
				name: 'CollectDetail',
				component: () =>
					import('@/views/continuation/image/detail'),
				meta: {
					title: '家庭图片',
					hidden: true,
				},
			},
		]
	},
	{
		path: '/collect',
		name: 'CollectRoot',
		component: Layout,
		meta: {
			title: '信息管理',
			icon: 'home-2-line',
		},
		children: [
			{
				path: 'index',
				name: 'CollectList',
				component: () =>
					import('@/views/continuation/collect/index'),
				meta: {
					title: '信息录入',
					// hidden: true,
				},
			},
			{
				path: 'detail',
				name: 'CollectDetail',
				component: () =>
					import('@/views/continuation/collect/detail'),
				meta: {
					title: '家庭成员',
					hidden: true,
				},
			},
		],
	},
	{
		path: '/collectProof',
		name: 'CollectProofRoot',
		component: Layout,
		meta: {
			title: '校对管理',
			icon: 'home-2-line',
		},
		children: [
			{
				path: 'index',
				name: 'CollectProof',
				component: () =>
					import('@/views/continuation/proof/index'),
				meta: {
					title: '信息校对',
					// hidden: true,
				},
			},
			{
				path: 'proofEdit',
				name: 'CollectProofEdit',
				component: () =>
					import('@/views/continuation/proof/edit'),
				meta: {
					title: '信息编辑——增减改错',
					// hidden: true,
				},
			},
			/* {
				path: 'proofReplace',
				name: 'CollectProofReplace',
				component: () =>
					import('@/views/continuation/proof/replace'),
				meta: {
					title: '信息编辑——替换',
					// hidden: true,
				},
			}, */
			/* {
				path: 'proofCopy',
				name: 'CollectProofCopy',
				component: () =>
					import('@/views/continuation/proof/copy'),
				meta: {
					title: '信息定稿',
					// hidden: true,
				},
			}, */
		],
	},
	
	/* {
		path: 'discounts',
		name: 'Discounts',
		component: () =>
			import('@/views/continuation/enter/Discounts'),
		meta: {
			title: '优惠管理',
		},
	}, */
]

// 家族管理功能页路径
export const familyRoutes = [
	{
		path: '/family',
		name: 'FamilyRoot',
		component: Layout,
		meta: {
			title: '家族管理',
			icon: 'home-2-line',
		},
		children: [
			{
				path: 'index',
				name: 'FamilyIndex',
				component: () =>
					import('@/views/family/index'),
				meta: {
					title: '基本信息',
				},
			},
			{
				path: 'branchs',
				name: 'Branchs',
				component: () =>
					import('@/views/family/Branchs'),
				meta: {
					title: '分支管理',
				},
			},
			{
				path: 'members',
				name: 'FamilyMembers',
				component: () =>
					import('@/views/family/members/index'),
				meta: {
					title: '家族成员管理',
				},
			},
			{
				path: 'councils',
				name: 'Councils',
				component: () =>
					import('@/views/family/Councils'),
				meta: {
					title: '理事会管理',
				},
			},
		],
	}
]

// 补充路径
export const asyncRoutes = [{
	name: "System",
	path: "http://admin.xupu360.com",
	meta: {
		title: "系统管理",
		icon: 'home-2-line',
		levelHidden: true,
	}
}]

function fatteningRoutes(routes) {
	return routes.flatMap((route) => {
		return route.children ? fatteningRoutes(route.children) : route
	})
}

export function resetRouter(routes = constantRoutes) {
	routes.map((route) => {
		if (route.children) {
			route.children = fatteningRoutes(route.children)
		}
	})
	router.matcher = createRouter(routes).matcher
}

function createRouter(routes = constantRoutes) {
	return new VueRouter({
		base: publicPath,
		mode: routerMode,
		scrollBehavior: () => ({
			y: 0,
		}),
		routes: routes,
	})
}

const router = createRouter()

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

export default router
