/**
 * @description 导入所有 vuex 模块，自动加入namespaced:true，用于解决vuex命名冲突，请勿修改。
 */
import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedstate from 'vuex-persistedstate'
import {
	STORAGE_KEY
} from "@/config"

Vue.use(Vuex)

const modules = {}
const files = require.context('./modules', false, /\.js$/)
files.keys().forEach((key) => {
	modules[key.replace(/(modules|\/|\.|js)/g, '')] = {
		...files(key).default,
		namespaced: true,
	}
})

const store = new Vuex.Store({
	modules,
	// 将插件配置到Vuex的plugins中
	plugins: [
		createPersistedstate({
			key: STORAGE_KEY, // 存数据的key名   自定义的  要有语义化
			paths: ['history', 'user', 'tabs', 'routes'] // 要把那些模块加入缓存
		})
	]
})
export default store
