/**
 * @description 导出网络配置
 **/
module.exports = {
  // 配后端数据的接收方式application/json;charset=UTF-8 或 application/x-www-form-urlencoded;charset=UTF-8
  contentType: 'application/json;charset=UTF-8',
  // 最长请求时间
  requestTimeout: 3600000,
  // 默认请求时长
  defaultTimeout: 20000,
  // 操作正常code，支持String、Array、int多种类型
  successCode: [200, 1, '200', '1'],
  // 数据状态的字段名称
  statusName: 'success',
  // 状态信息的字段名称
  messageName: 'errorMsg',
}
