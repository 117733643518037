/**
 * 业务记录集，比如当前家族、当前谱书、当前续谱业务数据等
 */
import {
	getFamily,
	setFamily,
	
	getFamilyBooks,
	setFamilyBooks,
	
	getBook,
	setBook,
	
	getBookVolumes,
	setBookVolumes,
	
	getContinuation,
	setContinuation,
} from "@/utils/storage"

const state = () => ({
	family: getFamily(),
	books: getFamilyBooks(),
	book: getBook(),
	volumes: getBookVolumes(),
	volume: {},
	volumePart: {},
	continuation: getContinuation(),
	visitType: "",
	
	booksetting: {},
	bookLayoutSetting: {},
	spinesetting: {},
	
	exportRecords: [], // 待导出记录集
	headNodes: [], // 当前头节点集合
})

const getters = {
	family: (state) => state.family,
	books: (state) => state.books,
	book: (state) => state.book,
	volumes: (state) => state.volumes,
	volume: (state) => state.volume,
	volumePart: (state) => state.volumePart,
	continuation: (state) => state.continuation,
	visitType: (state) => state.visitType,
	
	booksetting: (state) => state.booksetting,
	bookLayoutSetting: (state) => state.bookLayoutSetting,
	spinesetting: (state) => state.spinesetting,
	
	exportRecords: (state) => state.exportRecords,
	headNodes: (state) => state.headNodes,
}

const mutations = {
	/**
	 * @description 设置family
	 * @param {*} state
	 * @param {*} family
	 */
	setFamily(state, family) {
		family.otherInfo = family.otherInfo || {}
		if(typeof family.otherInfo == "string" && family.otherInfo != "{}") {
			family.otherInfo = JSON.parse(family.otherInfo)
		}
		state.family = family
		setFamily(family);
	},
	/**
	 * @description 设置books
	 * @param {*} state
	 * @param {*} books
	 */
	setBooks(state, books) {
		state.books = books
		setFamilyBooks(books);
	},
	/**
	 * @description 设置book
	 * @param {*} state
	 * @param {*} book
	 */
	setBook(state, book) {
		state.book = book
		setBook(book);
	},
	/**
	 * @description 设置volume
	 * @param {*} state
	 * @param {*} volume
	 */
	setVolumes(state, volumes) {
		state.volumes = volumes
		setBookVolumes(volumes);
	},
	/**
	 * @description 设置volume
	 * @param {*} state
	 * @param {*} volume
	 */
	setVolume(state, volume) {
		state.volume = volume
	},
	/**
	 * @description 设置分卷
	 * @param {*} state
	 * @param {*} volumePart
	 */
	setVolumePart(state, volumePart) {
		state.volumePart = volumePart
	},
	/**
	 * @description 设置continuation
	 * @param {*} state
	 * @param {*} continuation
	 */
	setContinuation(state, continuation) {
		state.continuation = continuation
		setContinuation(continuation);
	},
	/**
	 * @description 设置visitType
	 * @param {*} state
	 * @param {*} visitType
	 */
	setVisitType(state, visitType) {
		state.visitType = visitType
		sessionStorage.setItem("VISIT_TYPE", visitType);
	},
	/**
	 * @description 设置booksetting
	 * @param {*} state
	 * @param {*} booksetting
	 */
	setBooksetting(state, booksetting) {
		state.booksetting = booksetting
	},
	/**
	 * @description 设置bookLayoutSetting
	 * @param {*} state
	 * @param {*} bookLayoutSetting
	 */
	setBookLayoutSetting(state, bookLayoutSetting) {
		state.bookLayoutSetting = bookLayoutSetting
	},
	/**
	 * @description 设置spinesetting
	 * @param {*} state
	 * @param {*} spinesetting
	 */
	setSpinesetting(state, spinesetting) {
		state.spinesetting = spinesetting
	},
	
	/**
	 * @description 设置exportRecords
	 * @param {*} state
	 * @param {*} exportRecords
	 */
	setExportRecords(state, exportRecords) {
		state.exportRecords = [].concat(exportRecords)
	},
	
	/**
	 * @description 设置headNodes
	 * @param {*} state
	 * @param {*} headNodes
	 */
	setHeadNodes(state, headNodes) {
		state.headNodes = [].concat(headNodes)
	},
}

const actions = {
	/**
	 * @description 设置family
	 * @param {*} { commit }
	 * @param {*} family
	 */
	setFamily({
		commit
	}, family) {
		commit('setFamily', family)
	},
	/**
	 * @description 设置books
	 * @param {*} { commit }
	 * @param {*} books
	 */
	setBooks({
		commit
	}, books) {
		commit('setBooks', books)
	},
	/**
	 * @description 设置book
	 * @param {*} { commit }
	 * @param {*} book
	 */
	setBook({
		commit
	}, book) {
		commit('setBook', book)
	},
	/**
	 * @description 设置volumes
	 * @param {*} { commit }
	 * @param {*} volumes
	 */
	setVolumes({
		commit
	}, volumes) {
		commit('setVolumes', volumes)
	},
	/**
	 * @description 设置volume
	 * @param {*} { commit }
	 * @param {*} volume
	 */
	setVolume({
		commit
	}, volume) {
		commit('setVolume', volume)
	},
	/**
	 * @description 设置分卷
	 * @param {*} { commit }
	 * @param {*} volumePart
	 */
	setVolumePart({
		commit
	}, volumePart) {
		commit("setVolumePart", volumePart)
	},
	/**
	 * @description 设置continuation
	 * @param {*} { commit }
	 * @param {*} continuation
	 */
	setContinuation({
		commit
	}, continuation) {
		commit('setContinuation', continuation)
	},
	/**
	 * @description 设置visitType
	 * @param {*} { commit }
	 * @param {*} visitType
	 */
	setVisitType({
		commit
	}, visitType) {
		commit('setVisitType', visitType)
	},
	/**
	 * @description 设置booksetting
	 * @param {*} state
	 * @param {*} booksetting
	 */
	setBooksetting({
		commit
	}, booksetting) {
		commit('setBooksetting', booksetting)
	},
	/**
	 * @description 设置bookLayoutSetting
	 * @param {*} state
	 * @param {*} bookLayoutSetting
	 */
	setBookLayoutSetting({
		commit
	}, bookLayoutSetting) {
		commit('setBookLayoutSetting', bookLayoutSetting)
	},
	/**
	 * @description 设置spinesetting
	 * @param {*} state
	 * @param {*} spinesetting
	 */
	setSpinesetting({
		commit
	}, spinesetting) {
		commit('setSpinesetting', spinesetting)
	},
	
	/**
	 * @description 设置exportRecords
	 * @param {*} state
	 * @param {*} exportRecords
	 */
	setExportRecords({
		commit
	}, exportRecords) {
		commit('setExportRecords', exportRecords)
	},
	
	/**
	 * @description 设置headNodes
	 * @param {*} state
	 * @param {*} headNodes
	 */
	setHeadNodes({
		commit
	}, headNodes) {
		commit('setHeadNodes', headNodes)
	},
}

export default { state, getters, mutations, actions }